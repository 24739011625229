import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Box, Flex, Text, Button, useColorModeValue } from '@chakra-ui/react';
import { Meeting } from 'views/deals/Deal';
import MeetingDetails from 'views/meeting/components/MeetingDetails';
import MeetingSummaryCard from 'views/meeting/components/MeetingSummaryCard';
import Card from 'components/card/Card';
import InsightSectionWithFeedback from 'components/feedback/InsightSectionWithFeedback';

export default function MeetingOverviewModal(props: { meeting: Meeting, isOpen: boolean, onClose: () => void, onAddToDealClicked: (meeting: Meeting) => void }) {
  const { meeting, isOpen, onClose, onAddToDealClicked } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const handleAddToDealClick = () => {
    onAddToDealClicked(meeting)
  };

  if (!meeting) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="1400px" borderRadius={'30px'}>
        <ModalHeader>
          <Flex align={'center'} justify={'space-between'}>
            <Text fontSize={'xl'} fontWeight={'bold'} color={textColor}>{meeting.name}</Text>
            {handleAddToDealClick && <Button
              variant='outline'
              p='15px'
              me={'50px'}
              fontSize='sm'
              fontWeight='500'
              bg={'transparent'}
              color={'brand.500'}
              borderWidth={'1px'}
              borderColor={'brand.500'}
              onClick={handleAddToDealClick}
            >
              Add to Deal
            </Button>}
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Flex direction='column'>
              <MeetingDetails meetingDetail={meeting} variant='dashBoard' />
              <MeetingSummaryCard meetingDetails={meeting} />
              {meeting.actionItems?.length > 0 && <Flex
                mt={"15px"}
              >
                <Card w='100%'>
                  <InsightSectionWithFeedback insights={meeting.actionItems} sectionHeader={'Action Items'} />
                </Card>
              </Flex>}
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
