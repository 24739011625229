import { Flex, Text, useColorModeValue, UnorderedList, ListItem, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Accordion, Checkbox, Button } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InsightSectionWithFeedback from 'components/feedback/InsightSectionWithFeedback';
import { ActionItem } from 'views/deals/Deal';
import { useState } from 'react';
import { NextButton } from 'views/common/common';

export default function ActionItems(props: {
  header?: string,
  actionItems: any,
  variant?: string,
  [x: string]: any
}) {
  const { actionItems, variant, header, ...rest } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const [items, setItems] = useState(actionItems);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [modifiedItems, setModifiedItems] = useState(new Set<number>());

  const handleCheckboxChange = (index: number) => {
    const newItems = [...items];
    newItems[index].content.completed = !newItems[index].content.completed;
    setItems(newItems);
    setShowSaveButton(true);
    setModifiedItems(new Set(modifiedItems).add(index));
  };

  const handleSaveChanges = async () => {
    const updateAIurl = 'https://inwise-node-functions2.azurewebsites.net/api/update-action-items?code=m77KDLEjwxmozk4-gyYVipFuDUXMlyaw3skX_FZBKx5NAzFu6ViGCQ%3D%3D';
    try {
      const modifiedActionItems = Array.from(modifiedItems).map(index => items[index]);
      const response = await fetch(updateAIurl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ modifiedActionItems }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setShowSaveButton(false);
      setModifiedItems(new Set());
      console.log('Action items saved:', modifiedActionItems);
    } catch (error) {
      console.error('Error saving action items:', error);
    }
  };

  if (!items) return null;

  if (variant === 'recommended') {
    const defineContent = (item: any) => {
      if (item.substr(-15) === 'to be discussed') {
        const insightString = item.slice(0, -16).replace(/([A-Z])/g, ' $1').toLowerCase().trim();
        const content = <Text> <Text as="strong">{insightString.charAt(0).toUpperCase() + insightString.slice(1)}</Text>{item.slice(-16)}</Text>
        return <ListItem ml={2}>{content}</ListItem>
      }
    }
    return (
      <Card mt='20px' p='20px'>
        <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px'>
          <Flex align='center' textAlign='center' pb='10px'>
            <Text fontWeight={'bold'} color={textColor}>Recommended Action Items</Text>
          </Flex>
          <UnorderedList fontSize={'16px'}>
            {items.map((ai: string) => (
              defineContent(ai)))
            }
          </UnorderedList>
        </Flex>
      </Card>
    )
  }

  const parseDeadline = (deadline: string | null): Date | null => {
    return deadline ? new Date(deadline) : null;
  };

  const now = new Date();
  const pendingCompleted: any[] = [];
  const pendingIncomplete: any[] = [];
  const pastCompleted: any[] = [];
  const pastIncomplete: any[] = [];

  items.forEach((item: any, index: number) => {
    const deadline = parseDeadline(item.content.deadline);
    const itemWithCheckbox = {
      ...item,
      checkbox: (
        <Checkbox
          isChecked={item.content.completed}
          onChange={() => handleCheckboxChange(index)}
        />
      )
    };

    if (deadline === null || deadline >= now) {
      if (item.content.completed) {
        pendingCompleted.push(itemWithCheckbox);
      } else {
        pendingIncomplete.push(itemWithCheckbox);
      }
    } else {
      if (item.content.completed) {
        pastCompleted.push(itemWithCheckbox);
      } else {
        pastIncomplete.push(itemWithCheckbox);
      }
    }
  });

  const sortByDeadline = (a: any, b: any) => {
    const deadlineA = parseDeadline(a.content.deadline);
    const deadlineB = parseDeadline(b.content.deadline);
    if (deadlineA === null && deadlineB === null) return 0;
    if (deadlineA === null) return 1;
    if (deadlineB === null) return -1;
    return deadlineA.getTime() - deadlineB.getTime();
  };

  [pendingCompleted, pendingIncomplete, pastCompleted, pastIncomplete].forEach(arr => arr.sort(sortByDeadline));

  const completed: any[] = [...pendingCompleted, ...pastCompleted];

  if (pendingIncomplete.length === 0 && completed.length === 0 && pastIncomplete.length === 0) {
    return null;
  }

  return (
    <Card mt='20px' p='20px'>
      <Flex justifyContent="space-between" alignItems="center" >
        <Text fontWeight={'bold'} color={textColor} fontSize={'xl'}>
          Action Items
        </Text>
        {showSaveButton && (
          <NextButton onClick={handleSaveChanges} buttonText={'Save'} w={'50px'} maxH={'30px'} />
        )}
      </Flex>
      {pendingIncomplete.length > 0 && (
        <Flex flexDir="column">
          {pendingIncomplete.map((item: any, index: number) => (
            <Flex key={index} align="center">
              <InsightSectionWithFeedback
                insights={[item]}
                sectionHeader={null}
                listStyleType="none"
                {...rest}
              />
            </Flex>
          ))}
        </Flex>
      )}
      {pastIncomplete.length > 0 && (
        <>
          <Text fontWeight={'bold'} color={textColorTertiary} fontSize={'sm'} mt={4}>
            Overdue - Incomplete
          </Text>
          <Flex flexDir="column">
            {pastIncomplete.map((item: any, index: number) => (
              <Flex key={index} align="center">
                <InsightSectionWithFeedback
                  insights={[item]}
                  sectionHeader={null}
                  listStyleType="none"
                  {...rest} mx={0}
                />
              </Flex>
            ))}
          </Flex>
        </>
      )}
      {completed.length > 0 && (
        <Accordion allowMultiple>
          <AccordionItem key={'0'} borderBottomColor={'transparent'} mt={2}>
            <AccordionButton pl={'8px'}>
              <Box as='span' flex='1' textAlign='left'>
                <Text fontWeight={'bold'} color={textColorTertiary} fontSize={'sm'}>{`Completed (${completed.length})`}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} p={0}>
              {completed.map((item: any, index: number) => (
                <Flex key={index} align="center">
                  <InsightSectionWithFeedback
                    insights={[item]}
                    sectionHeader={null}
                    listStyleType="none"
                    {...rest}
                  />
                </Flex>
              ))}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Card>
  );
}
