import { Flex, Text, useColorModeValue, UnorderedList, Icon } from '@chakra-ui/react';
import { InsightFeedbackContainer } from './InsightFeedbackContainer';
import { Insight } from '../../views/deals/Deal';
import { useState } from 'react';

export default function InsightSectionWithFeedback(props: {
  insights: Insight[],
  sectionHeader: string,
  icon?: any,
  listStyleType?: string,
  [x: string]: any
}) {
  const { insights, sectionHeader, icon, listStyleType = 'disc', ...rest } = props
  const textColor = useColorModeValue('#2B3674', 'white');
  const headingColor = useColorModeValue('navy.800', 'white');

  //this is to prevent triggering other insights' popups when trying to dismiss this one
  //todo: check if worth it later. and if yes then should it be expanded to parent container?
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!insights || insights.length < 1) {
    if (!sectionHeader) return null;
    return (
      <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px' {...rest}>
        <Flex align='center' textAlign='center' pb='10px'>
          {icon &&
            <Flex align='center' justify='center' textAlign='center' backgroundColor='#F4F7FE' borderRadius='25%' mr='5px' display='block'>
              <Icon as={icon} w='20px' h='20px' color={headingColor} overflow='visible' mr='15px' ml='5px' mb='15px' mt='5px' />
            </Flex>
          }
          <Text fontWeight={'bold'} color={textColor}>{sectionHeader}</Text>
        </Flex>
      </Flex>);
  }
  return (
    <Flex flexDir={'column'} flexGrow={1} flexBasis={0} {...rest}>
      <Flex align='center' textAlign='center' pb='10px'>
        {icon &&
          <Flex align='center' justify='center' textAlign='center' backgroundColor='#F4F7FE' borderRadius='25%' mr='5px' display='block'>
            <Icon as={icon} w='20px' h='20px' color={headingColor} overflow='visible' mr='15px' ml='5px' mb='15px' mt='5px' />
          </Flex>
        }
        <Text fontWeight={'bold'} color={textColor}>{sectionHeader}</Text>
      </Flex>
      <UnorderedList fontSize={'16px'} listStyleType={listStyleType} ml={listStyleType == 'none' ? 0 : '20px'}>
        {insights.map((item) => (
          <InsightFeedbackContainer insight={item} key={item.insightId} isPopupOpen={isPopupOpen} onPopupToggle={(isOpen) => setIsPopupOpen(isOpen)} />))
        }
      </UnorderedList>
    </Flex>);
};
