import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import MeetingDetails from './components/MeetingDetails';
import MeetingSummaryCard from './components/MeetingSummaryCard';
import { Meeting, GetCollectionItemResponse } from 'views/deals/Deal';
import { SidebarContext } from 'contexts/SidebarContext';

const getMeetingItemUrl = "https://appwise-functions.azurewebsites.net/api/get-meeting-one?code=1M-q0chwXgwmvhboVXOSenT8nfID70SjanL5oa3deZObAzFuUgaTcQ%3D%3D";

export default function MeetingsOverview() {
  let { meetingId } = useParams<{ meetingId: string }>();
  const { setBrandText } = useContext(SidebarContext);
  const [meetingDetails, setMeetingDetails] = useState<Meeting>();
  useEffect(() => {
    fetch(getMeetingItemUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO: add JWT
      body: JSON.stringify({ meetingId })
    })
      .then(res => res.json())
      .then(data => {
        let { item } = data as GetCollectionItemResponse;
        setMeetingDetails(item);
        setBrandText(item.name?.charAt(0).toUpperCase() + item.name?.slice(1));
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  if (!meetingDetails) return null;
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction='column' mb="50px">
        <MeetingDetails meetingDetail={meetingDetails} />
        <MeetingSummaryCard meetingDetails={meetingDetails} />
      </Flex>
    </Box>
  );
}
