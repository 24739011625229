import { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  UnorderedList,
  ListItem,
  Button,
  Spinner,
  SkeletonText,
  Container,
  Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { GetCollectionItemResponse, Meeting } from 'views/deals/Deal';
import { set } from "react-hook-form";
// import MeetingSentimentChart from "components/charts/MeetingSentiment";
import { List } from "echarts";
import InsightSectionWithFeedback from "components/feedback/InsightSectionWithFeedback";
// import MeetingSentimentGauge from "components/charts/MeetingSentimentGauge";
import { InfoIcon } from "@chakra-ui/icons";

const getCollectionItemUrl = "https://appwise-functions.azurewebsites.net/api/get-collection-one?code=S1w5aTnQUwAtDUkrQbCbGN89hBkYLes3IJaFvQX9Qq9rAzFufyTSCg%3D%3D";
const summarizeTranscriptUrl = "https://inwise-node-functions2.azurewebsites.net/api/generate-transcript-summarize?code=UbRusF_EZIq1EgnlLw7JsruHzXq0DnlwkU-WjH5z7Pp4AzFu8r-jFA%3D%3D";

function MeetingOverviewTab(props: {
  activeTab: number;
  index: number;
  name: any;
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorTertiary = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  const { activeTab, index, name } = props;
  return (
    <Tab
      key={index}
      p="0px"
      flexDirection="column"
      _focus={{ boxShadow: "none" }}
      borderRadius="0px"

    >
      <Flex align="center" borderBottom={activeTab == index ? "3px solid brand.900" : "none"}>
        <Text
          color={activeTab == index ? 'brand.900' : textColorTertiary}

          fontSize="lg"
          fontWeight="500"
          m={"10px 20px"}
        >
          {name}
        </Text>
      </Flex>
    </Tab>
  );
}

function MeetingSummaryTab(props: { meetingSummaryContent: string[], isSummaryLoading: boolean }) {
  const textColor = useColorModeValue("navy.700", "white");
  const { meetingSummaryContent, isSummaryLoading } = props;
  if (!meetingSummaryContent) return null
  return (
    <Box>
      <Box paddingLeft="20px">
        {!isSummaryLoading ? meetingSummaryContent?.map((meetingSummaryPoint, index) => (
          <Box key={index}>
            <Text fontSize={"md"} color={textColor} ms="auto" me="6px">
              {meetingSummaryPoint}
            </Text>
          </Box>
        )) :
          <Box mt='6px'>
            <Flex dir="row" align='center' justify={'flex-start'}>
              <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' />
              <Text fontSize={"md"} color={textColor} me="6px" ml='12px'>
                summarizing meeting transcript
              </Text>
            </Flex>
            <SkeletonText mt='4' noOfLines={7} spacing='4' skeletonHeight='2' />
          </Box>}
      </Box>
    </Box>
  );
}
function TranscriptEntry(props: { speaker: string, time: string, text: string }) {
  const textColor = useColorModeValue("navy.700", "white");

  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const { speaker, time, text } = props;
  //time is in s.s format, convert to (hh:)mm:ss
  const timestamp = secondsToTimestamp(time);
  return (
    <Box mb={"20px"}>
      <Flex w="100%">
        <Text fontSize={"sm"} fontWeight={"bold"} color={textColor} mr="10px">
          {speaker}
        </Text>
        <Text fontSize={"sm"} color={textColorTertiary}>
          {timestamp}
        </Text>
      </Flex>
      <Text fontSize={"md"} color={textColor}>
        {text}
      </Text>
    </Box>
  );
}

function TranscriptEntryList(props: { transcript: string }) {
  const textColor = useColorModeValue("navy.700", "white");
  const { transcript } = props;
  if (!transcript) return null
  const transcriptArray = transcript.split('\n\n');
  if (transcriptArray.length === 0) return null;
  // remove empty strings
  if (transcriptArray[0].trim() === '') transcriptArray.shift();
  return (
    <Box>
      {transcriptArray.map((transcriptEntry, index) => {
        const timestamp = transcriptEntry.split(' ')[0];
        const transcriptItems = transcriptEntry.split(':');
        const speaker = transcriptItems[0].split(' ')[1];
        const text = transcriptItems.slice(1).join(':').trim();

        return (
          <TranscriptEntry key={index} speaker={speaker} time={timestamp} text={text} />
        );
      })}
    </Box>
  );
}

function TranscriptTab(props: {
  meeting: Meeting,
  setActiveTabFunction: Function,
  setIsLoadingFunction: Function,
}) {
  const { meeting, setActiveTabFunction, setIsLoadingFunction } = props;
  const [meetingTranscript, setMeetingTranscript] = useState<String>();
  const buttonRef = useRef(null);
  const textColor = useColorModeValue("navy.700", "white");
  useEffect(() => {
    if (meeting.meetingTranscriptId) {
      fetch(getCollectionItemUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // TODO: add JWT
        body: JSON.stringify({
          _id: meeting.meetingTranscriptId,
          collectionName: "MeetingTranscript"
        })
      })
        .then(res => res.json())
        .then(data => {
          let { item } = data as GetCollectionItemResponse;
          let { _id, wiseCompanyId, transcript, date, speakers } = item;
          setMeetingTranscript(transcript);
        })
        .catch((error: Error) => {
          console.log(error);
        });
    }
  }, []);
  return (
    <Box px="20px">
      <Text color={textColor} fontSize="16px" fontWeight="400" style={{ whiteSpace: "pre-wrap" }}>
        {meetingTranscript ? <TranscriptEntryList transcript={meetingTranscript.toString()} /> : "Loading transcript..."}
      </Text>
    </Box>
  );
}

function AspectSentimentContainer(props: {
  aspect: any,
  [x: string]: any;
}) {
  const { aspect, ...rest } = props;
  const textColor = useColorModeValue("navy.700", "white");
  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  if (!aspect || !aspect.description) return null
  // if the sentiments of all attendees are the same return= a combined sentiment
  const unifiedSentiment = (aspect.sentiments.every((s: any) => s.sentimentScore === aspect.sentiments[0].sentimentScore))
  if (!unifiedSentiment) {
    return (
      <Flex w={'100%'} direction={'column'} {...rest}>
        <ListItem>
          <Text fontSize={"sm"} color={textColor} w={'100%'} mb={'10px'}>
            {aspect?.description}
          </Text>
        </ListItem>

        {aspect?.sentiments?.map((sentiment: any, index: number) => (
          <Flex w={'100%'} direction='column'>
            <Flex w={'100%'} justifyContent='space-between' pr={'10px'}>
              <Text key={index} fontSize={"sm"} color={textColor} ml='12px'>
                {sentiment?.clientName}
              </Text>
              <Text key={index} fontSize={"sm"} color={textColor} ml='12px'>
                {sentiment?.sentimentScore}
              </Text>
            </Flex>
            {sentiment?.sentiments?.map((sentiment: any, index: number) => (
              <Text key={index} fontSize={"sm"} fontWeight={'semibold'} color={textColorTertiary} ml='20px'>
                - {sentiment}
              </Text>
            ))}

          </Flex>
        ))}
      </Flex>
    );
  }
  else {
    //combine sentiment.sentiments into one array without duplicates
    let combinedSentiments = aspect.sentiments.map((s: any) => s.sentiments).flat()
    combinedSentiments = Array.from(new Set(combinedSentiments))
    return (
      <Flex w={'100%'} direction={'column'} {...rest}>
        <ListItem>
          <Flex w={'100%'} justifyContent='space-between' pr={'10px'}>
            <Text fontSize={"sm"} color={textColor} w={'100%'} mb={'10px'}>
              {aspect?.description}
            </Text>
            <Text fontSize={"sm"} color={textColor} ml='12px'>
              {aspect?.sentiments[0]?.sentimentScore}
            </Text>
          </Flex>
        </ListItem>

        <Flex w={'100%'} direction='column'>
          {combinedSentiments.map((sentiment: any, index: number) => (
            <Text key={index} fontSize={"sm"} color={textColor} ml='20px'>
              - {sentiment}
            </Text>
          ))}

        </Flex>
      </Flex>
    );
  }
}
function secondsToTimestamp(value: string): string {
  // value is in s.s format
  const s = parseFloat(value);
  const hours = Math.floor(s / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const seconds = Math.floor(s % 60);
  const hString = hours > 0 ? ('0' + hours).slice(-2) + ':' : '';
  return `${hString}${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
}

function MeetingSentimentTab(props: {
  meeting: any,
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const { meeting } = props;
  if (!meeting) return null;
  const segments = meeting.sentimentAnalysis?.segments
  const [hoveredIndex, setHoveredIndex] = useState(-1);
  //let hoveredIndex: number | null = null;
  function handleSegmentHover(segmentInd: number | null): void {
    setHoveredIndex(segmentInd ? segmentInd - 1 : -1);
  }
  const durationStrings = segments?.map((segment: any) => {
    return `${secondsToTimestamp(segment.start_time)} - ${secondsToTimestamp(segment.end_time)}`;
  });
  return (
    <Box mx='20px' >
      {segments &&
        <Flex justify={'flex-start'} h='100%'>
          <Flex ml="20px" w={'65%'}>
            {/* <MeetingSentimentChart meeting={meeting} onHoverChanged={handleSegmentHover} /> */}
          </Flex>
          <Flex w='35%' h='900px' mt={'0px'}>
            {hoveredIndex >= 0 && hoveredIndex < segments.length ? (
              <Flex w={'100%'} flexDir={'column'}
                pb={'15px'}
                pr={'20px'}
              >
                <Text fontSize={"md"} color={textColorTertiary} mb={'10px'}>
                  {durationStrings[hoveredIndex]}
                </Text>
                <Text fontSize={"md"} color={textColor} mb={'10px'}>
                  {segments[hoveredIndex].topic}
                </Text>
                <Text fontSize={"sm"} color={textColor}>
                  {segments[hoveredIndex].description}
                </Text>
                <Text fontSize={"sm"} fontWeight={'bold'} color={textColor} mt={'20px'}>
                  {segments[hoveredIndex].sentimentDescription}
                </Text>
                {segments[hoveredIndex].clientIntents && segments[hoveredIndex].clientIntents.length > 0 &&
                  <Flex direction="column" align='flex-start' w={'100%'} >
                    <Text fontSize={"sm"} color={textColor} mt={'20px'} mb={'10px'}>
                      Client Intents:
                    </Text>
                    <UnorderedList w={'100%'} pr={'20px'} pl={'5px'}>
                      {segments[hoveredIndex].clientIntents.map((intent: any, index: number) => (
                        <ListItem key={index}>
                          <Text fontSize={"sm"} color={textColor} w={'100%'}>
                            {intent}
                          </Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Flex>
                }
                {segments[hoveredIndex].clientSentiments && segments[hoveredIndex].clientSentiments.length > 0 &&

                  <Flex direction="column" align='flex-start' w={'100%'} >
                    <Text fontSize={"sm"} color={textColor} mt={'20px'} mb={'10px'}>
                      Client Sentiments:
                    </Text>
                    <UnorderedList w={'100%'} pr={'20px'} pl={'5px'}>
                      {segments[hoveredIndex].clientSentiments.map((sentiment: any, index: number) => (
                        <ListItem key={index}>
                          <Text fontSize={"sm"} color={textColor} w={'100%'}>
                            {sentiment}
                          </Text>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </Flex>
                }
                {segments[hoveredIndex].aspects && segments[hoveredIndex].aspects.length > 0 &&
                  <Flex direction="column" align='flex-start' w={'100%'} >
                    <Text fontSize={"sm"} color={textColor} mt={'20px'} mb={'10px'}>
                      Aspect Sentiments:
                    </Text>
                    <UnorderedList w={'100%'} pr={'20px'} pl={'5px'}>
                      {segments[hoveredIndex].aspects.map((aspect: any, index: number) => (
                        <AspectSentimentContainer key={index} aspect={aspect} />
                      ))}
                    </UnorderedList>
                  </Flex>
                }
              </Flex>
            ) : (
              <Flex direction='column' w={'100%'} >
                <Flex>
                  <Icon as={InfoIcon} w="20px" h="20px" color={textColorTertiary} />

                  <Text fontSize={"md"} color={textColorTertiary} pl={'10px'}>
                    Hover over the chart to see the client sentiment throughout the meeting
                  </Text>
                </Flex>
                {meeting.sentimentAnalysis?.meetingScore &&
                  <Flex justifyContent={'space-between'} w={'100%'} my={'20px'} mr={'50px'} justifyItems={'end'}>
                    <Text fontSize={"md"} color={textColor} fontWeight={'semibold'} w={'100%'} alignSelf={'end'}>
                      Overall Sentiment
                    </Text>
                    {/* <MeetingSentimentGauge score={meeting.sentimentAnalysis.meetingScore} variant='mini' /> */}
                  </Flex>
                }
                {meeting.sentimentAnalysis?.meetingScoreDescription && <Text fontSize={"md"} color={textColor}>
                  {meeting.sentimentAnalysis.meetingScoreDescription}
                </Text>}
              </Flex>
            )}

          </Flex>
        </Flex>
      }
    </Box>
  );
}

function MeetingHighlightsTab(props: {
  meeting: any,
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const { meeting } = props;
  if (!meeting) return null;

  return (
    <Box ml='20px'>
      <InsightSectionWithFeedback
        insights={meeting.meetingHighlights}
        sectionHeader={null}
        mb='15px'
      />
      <InsightSectionWithFeedback
        insights={meeting.keyDecisions}
        sectionHeader={'Key Decisions'}
        mb='15px'
      />
      <InsightSectionWithFeedback
        insights={meeting.importantInsights}
        sectionHeader={'Important Insights'}
        mb='15px'
      />
      <InsightSectionWithFeedback
        insights={meeting.nextSteps}
        sectionHeader={'Next Steps'}
        mb='15px'
      />

    </Box>
  );
}

//TODO(KRIS) : this loses deal name meeting link etc everything thats in Meeting minus generatedDetails ( aka whats returned from 'transcript-summarize')
export default function MeetingSummaryCard(props: { meetingDetails: Meeting }) {
  const { meetingDetails } = props
  let [activeTab, setActiveTab] = useState<number>(meetingDetails?.generatedSummary ? 0 : 1);
  let [isLoading, setIsLoading] = useState<boolean>(false);

  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const borderColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.500"
  );
  return (
    <Card p={0}>
      <Tabs
        mb="10px"
        index={activeTab}
        onChange={(index) => setActiveTab(index)}
      >
        <TabList overflowX={{ sm: "scroll", lg: "unset" }}>
          <Flex borderBottom={`3px solid ${borderColor}`} w="100%">
            <MeetingOverviewTab
              activeTab={activeTab}
              index={0}
              name="Summary"
            />
            <MeetingOverviewTab
              activeTab={activeTab}
              index={1}
              name="Highlights"
            />
            <MeetingOverviewTab
              activeTab={activeTab}
              index={2}
              name="Sentiment"
            />
            <MeetingOverviewTab
              activeTab={activeTab}
              index={3}
              name="Transcript"
            />
          </Flex>
        </TabList>
        <TabPanels pr={'10px'}>
          <TabPanel px="0px">
            <MeetingSummaryTab meetingSummaryContent={meetingDetails.generatedSummary} isSummaryLoading={isLoading} />
          </TabPanel>
          <TabPanel px="0px">
            <MeetingHighlightsTab
              meeting={meetingDetails}
            />
          </TabPanel>
          <TabPanel px="0px">
            <MeetingSentimentTab
              meeting={meetingDetails}
            />
          </TabPanel>
          <TabPanel px="0px">
            <TranscriptTab
              meeting={meetingDetails}
              setActiveTabFunction={setActiveTab}
              setIsLoadingFunction={setIsLoading}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}

