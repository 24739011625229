import { Text, useColorModeValue, UnorderedList, ListItem, Button, Flex, Tooltip, Icon, Spinner } from '@chakra-ui/react';
import { BotIcon, BotOffIcon, EyeIcon } from 'components/icons/Icons';
import { useState } from 'react';
import { Meeting } from 'views/deals/Deal';
import { getFormattedDateTime } from 'views/deals/overview/components/MeetingDetailsCard';

export default function MeetingCard(props: { meeting: Meeting, variant?: 'pastMeetings', mb: string, onAddToDealClick?: (meeting: Meeting) => void, onViewMeetingClick?: (meeting: Meeting) => void }) {
  const { meeting, variant, mb, onAddToDealClick, onViewMeetingClick } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const toggleBotURL = "https://inwise-node-functions2.azurewebsites.net/api/toggle-recall-schedule?code=Hanabm5nEQAirSAToXK3Gz5bVSPJcSkpCm6ysH_6rx78AzFujOsAWA=="
  const [botScheduled, setBotScheduled] = useState(meeting.botId ? true : false);
  const [botLoading, setBotLoading] = useState(false);
  const handleBotToggle = async (meeting: Meeting) => {
    if (botLoading) return;
    setBotLoading(true);
    try {
      const response = await fetch(toggleBotURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ meetingId: meeting._id, schedule: !botScheduled }),
      });
      console.log('Response:', response);
      if (!response.ok) {
        console.error('Feedback successful');
        return;
      }
      const result = await response.json();
      console.log('Result:', result);
      if (result.success) {
        setBotScheduled(!botScheduled);
        setBotLoading(false);
        return true;
      } else {
        throw new Error(result.message || `Failed to update bot`);
      }
    }
    catch (error) { console.error('Error during fetch:', error) }
    setBotLoading(false);
  }
  const userEmail = localStorage.getItem("loggedUserEmail");
  const host = userEmail?.split('@')[1];
  //sort so user company comes last
  if (meeting.attendeesEmails && meeting.attendeesEmails.length > 0) {
    meeting.attendeesEmails.sort((a, b) => {
      if (a.includes(host) && !b.includes(host)) return 1;
      if (!a.includes(host) && b.includes(host)) return -1;
      return 0;
    });
  }

  console.log('User Email:', userEmail);
  return (
    <Flex
      direction={'column'}
      p={4}
      mb={mb}
      borderWidth='1px'
      borderRadius='lg'
      bg={useColorModeValue('white', 'gray.700')}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Text color={textColor} fontWeight='bold' display={'flex'} flexGrow={1} flexBasis={0}>
          {meeting.name}
        </Text>
        {meeting.deal.name === 'Not Assigned Meetings' ? (
          <>
            {onAddToDealClick && <Tooltip label={'Add to Deal'}>
              <Button fontSize='2xl' bg={'transparent'} fontWeight='700' size={'xs'} color={'brand.500'} w={'20px'} mt={'-10px'} mr={'-10px'} ml={'10px'} alignSelf={'flex-start'} onClick={() => onAddToDealClick(meeting)}>{'+'}</Button>
            </Tooltip>}
            {variant == 'pastMeetings' && onViewMeetingClick &&
              <Tooltip label={'View Meeting'}>
                <Button bg={'transparent'} size={'xs'} color={'brand.500'} w={'20px'} mt={'-10px'} mr={'-10px'} ml={'10px'} alignSelf={'flex-start'} onClick={() => onViewMeetingClick(meeting)}>
                  <Icon as={EyeIcon} w="25px" h="25px" />
                </Button>
              </Tooltip>}
          </>
        ) : (
          <Tooltip label={'View Deal'}>
            <Button bg={'transparent'} size={'xs'} color={'brand.500'} w={'20px'} mt={'-10px'} mr={'-10px'} ml={'10px'} alignSelf={'flex-start'} onClick={() => window.location.href = `/deals/overview/${meeting.deal._id}`}>
              <Icon as={EyeIcon} w="25px" h="25px" />
            </Button>
          </Tooltip>
        )}
        {
          (variant != 'pastMeetings' && (meeting.botId || meeting.unscheduledAt)) &&
          (!botLoading ? <Tooltip label={botScheduled ? 'Cancel Bot' : 'Schedule Bot'}>
            <Button bg={'transparent'} size={'xs'} disabled={botLoading} color={botScheduled ? 'brand.500' : textColorTertiary} w={'20px'} mt={'-6px'} mr={'-10px'} ml={'10px'} alignSelf={'flex-start'} borderRadius={0} onClick={() => handleBotToggle(meeting)}>
              <Icon as={botScheduled ? BotIcon : BotOffIcon} w="20px" h="20px" alignSelf={'flex-start'} />
            </Button>
          </Tooltip> :
            <Flex mt={'-16px'} mr={'-10px'} ml={'10px'} w={'20px'}>
              <Spinner color='brand.500' size={'xs'} emptyColor='gray.200' alignSelf={'flex-start'} justifySelf={'flex-start'} />
            </Flex>
          )
        }
      </Flex>
      <Text color={textColorTertiary} fontSize={'sm'}>
        {getFormattedDateTime(meeting)}
      </Text>
      {variant == 'pastMeetings' ? (
        <Text color={textColor} mt={2} fontSize={'md'}>
          {meeting.oneLineSummary}
        </Text>
      ) : meeting.attendees && meeting.attendees.length > 0 ? (
        <>
          <UnorderedList color={textColor} fontSize='sm' mt={2} pl={'5px'} maxH={'50px'} overflow='hidden'>
            {meeting.attendees.slice(0, 2).map((attendee, index) => (
              <ListItem key={index}>{attendee}</ListItem>
            ))}
          </UnorderedList>
          {meeting.attendees?.length > 2 &&
            <Text color={textColor} fontSize='sm' pl={'16px'}>
              {`+${meeting.attendees.length - 2} more`}
            </Text>
          }
        </>
      ) : meeting.attendeesEmails && meeting.attendeesEmails.length > 0 ? (
        <>
          <UnorderedList listStyleType='none' color={textColor} fontSize='sm' mt={2} pl={'0px'} maxH={'50px'} overflow='ellipse' ml={'0px'}>
            {meeting.attendeesEmails.slice(0, 2).map((email, index) => (
              <ListItem key={index} >{email}</ListItem>
            ))}
          </UnorderedList>
          {meeting.attendeesEmails?.length > 2 &&
            <Text color={textColor} fontSize='sm' pl={'0px'}>
              {`+${meeting.attendeesEmails.length - 2} more`}
            </Text>
          }
        </>
      ) : null}
    </Flex>
  );
}
