import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Spinner,
  useColorModeValue,
} from '@chakra-ui/react';

import React, { useEffect, useState } from "react";
import AddToDeal from 'views/deals/create/components/AddToDeal';
import { useHistory } from 'react-router-dom';
import FindProspect from 'views/common/FindProspect';
import SelectProspect from 'views/common/SelectProspect';
import { CompanySearchResult, ProspectSearchResult } from 'views/prospects/Prospect';
import { TabButton } from './common';

const searchPeopleDataURL = "https://inwise-node-functions2.azurewebsites.net/api/pdl-enrichment-search?code=PQdgvBwUMH-j9Fq4B2zVljPVNaMMCdz4pqKYztHEmytSAzFunx1HDA%3D%3D";
export interface ProspectFormData {
  firstName: string;
  lastName: string;
  company: string;
  location: string;
}

export default function SearchAndCreate(props: {
  variant: 'createDeal' | 'addProspect' | 'futureMeetings',
  prospects?: ProspectSearchResult[],
  onBack?: () => void,
  onCreated?: () => void,
  onLoading?: () => void,
  meetingId?: string
}) {
  const { variant, prospects, onBack, onCreated, onLoading, meetingId } = props;

  const findProspectTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const selectProspectTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const addToDealTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [prospectSearchResults, setProspectSearchResults] = useState<ProspectSearchResult[]>()
  const [prospect, setProspect] = useState<ProspectSearchResult>();
  const [isLoading, setIsLoading] = useState(false);
  const createDealOrchUrl = "https://inwise-node-functions2.azurewebsites.net/api/orchestrators/orchestration-create-deal?code=NvO3RzqrIOvIT--PtNm42g6gezEwgpLeRVTivmbSmi0dAzFuanX63g%3D%3D";
  const textColor = useColorModeValue('navy.700', 'white');
  interface CompanyFormData {
    companyName: string;
  }

  useEffect(() => {
    setProspectSearchResults(null)
    if (variant == 'futureMeetings' && prospects?.length > 0) {
      setProspectSearchResults(prospects);
    }
  }, []);

  async function fetchData(url: string, body: string) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      if (!response.ok) {
        console.error('Error fetching data:', response.status, response.statusText);
        return null;
      }
      return await response.json();

    } catch (error) {
      console.error('Error during fetch:', error);
      return null;
    }
  }

  const handleCreate = async (prospect: ProspectSearchResult, company: CompanySearchResult | null) => {

    if (variant != 'addProspect' && !company) { return; }
    setIsLoading(true);
    try {
      let body;
      if (variant == 'createDeal') {
        body = JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), id: company.id, prospect: prospect })
      }
      else if (variant == 'addProspect') {
        body = JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), prospect: prospect, dealId: localStorage.getItem('dealId') });
      }
      else if (variant == 'futureMeetings') {
        body = JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), id: company.id, prospect: prospect, meetingId })
      }
      const orchResult = await fetchData(createDealOrchUrl, body);

      const { statusQueryGetUri } = orchResult;

      // wait for orchestrator to finish
      let isComplete = false;
      if (onLoading) { onLoading(); }
      while (!isComplete) {
        const statusResponse = await fetch(statusQueryGetUri);

        if (!statusResponse.ok) {
          console.error('Failed to fetch orchestration status');
        }

        const statusData = await statusResponse.json();

        if (statusData.runtimeStatus === 'Completed') {
          isComplete = true;
          setIsLoading(false)
          const output = statusData.output;
          if (variant == 'createDeal') {
            const dealId = output?.dealId
            localStorage.setItem('dealId', dealId);
            history.push(`/deals/overview/${dealId}`);
          }
          if (onCreated) { onCreated(); }
        } else if (statusData.runtimeStatus === 'Failed') {
          console.error('Orchestration failed');
        } else {
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }

      }
    } catch (error) {
      console.error('Error during fetch:', error)
      setIsLoading(false)
    }
  }
  const history = useHistory();

  const handleFindProspectSubmit = async (formData: ProspectFormData) => {
    setProspectSearchResults(null);
    setTabIndex(tabIndex + 1);
    try {
      const response = await fetch(searchPeopleDataURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ command: 'people', data: formData }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setProspectSearchResults(responseData);
      } else {
        console.error('Error sending data:', response.status, response.statusText);
        setProspectSearchResults([]);
      }
    } catch (error) {
      console.error('Error sending data:', error);
      setProspectSearchResults([]);
    }
  };


  const handleSelectProspect = (selectedProspect: ProspectSearchResult) => {
    if (!selectedProspect) return
    setProspect(selectedProspect)
    setTabIndex(tabIndex + 1);
  }

  const handleReturnClicked = () => {
    if (tabIndex < 1) {
      onBack();
    }
    setTabIndex(tabIndex < 1 ? 0 : (tabIndex - 1))
  }

  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Flex direction='column' align='center' pt={variant == 'futureMeetings' ? '0px' : '75px'} position='relative'>
      <Box
        h={variant == 'futureMeetings' ? '100%' : '45vh'}
        bg={variant == 'futureMeetings' ? 'none' : 'linear-gradient(135deg, #04647C 0%, #00A6D1 100%)'}
        position='absolute'
        w='100%'
        borderRadius='30px'
      />
      {isLoading ?
        <Flex w={'100%'} h={'500px'} justifyContent='center' alignItems={'center'} flexDir={'column'}>
          <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' mb={'20px'} />
          <Text color={textColor} fontWeight={'700'}>Submitting...</Text>
        </Flex>

        : <Tabs
          variant='unstyled'
          mt={variant == 'futureMeetings' ? '0px' : '60px'}
          zIndex='0'
          display='flex'
          flexDirection='column' index={tabIndex} onChange={(index: number) => setTabIndex(index)} w={variant == 'futureMeetings' ? '100%' : { sm: '100%', md: '90%', lg: '80%', xl: '65%' }}>
          {variant != 'futureMeetings' && <TabList display='flex' alignItems='stretch' alignSelf='center' justifySelf='center' w={'100%'}>
            <TabButton ref={findProspectTab} label='Find Prospect' />
            <TabButton ref={selectProspectTab} label='Select Prospect' />
            <TabButton ref={addToDealTab} label={variant == 'createDeal' ? 'Create Deal' : 'Add to Deal'} />
          </TabList>}
          <TabPanels mt={variant == 'futureMeetings' ? '0px' : '24px'} mx='auto'>
            {(variant != 'futureMeetings' || (variant == 'futureMeetings' && !prospects)) && <TabPanel p='0px'>
              <FindProspect
                onSubmit={handleFindProspectSubmit}
                onReturn={variant == 'futureMeetings' ? handleReturnClicked : null} />
            </TabPanel>}
            <TabPanel p='0px'>
              <SelectProspect
                data={prospectSearchResults}
                onSelectProspect={handleSelectProspect}
                onReturn={handleReturnClicked} />
            </TabPanel>
            <TabPanel p='0px'>
              {prospect && <AddToDeal
                prospect={prospect}
                variant={variant}
                onConfirm={handleCreate}
                onReturn={handleReturnClicked} />}
            </TabPanel>
          </TabPanels>
        </Tabs>}
    </Flex>
  );
}
