import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Text, Tooltip, useColorModeValue, Tab, TabList, TabPanel, TabPanels, Tabs, Select, useToast, InputRightElement, Spinner, VStack, HStack, Switch } from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { HSeparator } from 'components/separator/Separator';
import { Deal, Meeting } from 'views/deals/Deal';
import MeetingCard from 'views/deals/overview/components/MeetingCard';
import { getFormattedDateTime } from 'views/deals/overview/components/MeetingDetailsCard';
import Card from 'components/card/Card';
import { BackButton, NextButton } from 'views/common/common';
import { EditIcon, PasswordVisibleIcon, PasswordHiddenIcon } from 'components/icons/Icons';
import DeleteAccountTab from './components/deleteAccount';
import AssignMeetingsTab from './components/assignMeetings';

import ManageCalendars from './components/manageCalendars';
import { generateCredentialKey } from '@azure/msal-common/lib/types/cache/utils/CacheHelpers';

const Settings = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }} w={'75%'} >
      <Tabs variant='unstyled' orientation='vertical' aria-orientation='vertical' h='100vh' pb={'30px'} ml={'10px'}>
        <TabList w={'25%'} minH={'500px'} minW={'200px'} pt={'10px'} borderColor='blackAlpha.300' borderRightWidth='1px'>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} borderTopLeftRadius='16px'>Account Settings</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Integrations</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Unassigned Meetings</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Delete Account</Tab>
          <Tab _selected={{ color: activeColor }} color={inactiveColor} _focus={{ color: 'none' }} justifyContent="stretch" textAlign={'start'} pl={'25px'} >Manage Calendars</Tab>
        </TabList>
        <TabPanels>
          <TabPanel py={'0px'}>
            <EditProfileTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <ViewIntegrationsTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <AssignMeetingsTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <DeleteAccountTab />
          </TabPanel>
          <TabPanel py={'0px'}>
            <ManageCalendars />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box >
  );
}

export default Settings;

function EditProfileTab() {
  const activeColor = useColorModeValue('gray.700', 'white');
  const inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  const underlineColor = useColorModeValue('gray', 'white');
  const { userDetails, isLoading, error } = useFetchUserDetails();
  const updateUserDetails = useUpdateUserDetails();
  // TODO: Add loading spinners
  if (isLoading) {
    return <Flex w={'100%'} h={'300px'} justifyContent={'center'} alignItems={'center'}><Spinner color='brand.500' thickness='3px' emptyColor='gray.200' mb={'20px'} /></Flex>;
  }

  if (error) {
    return <Box>Error Loading: {error}</Box>;
  }

  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Tabs variant="unstyled" isFitted>
        <TabList>
          {['Name', 'Email Address', 'Phone Number', 'Password'].map((tabName) => (
            <Tab
              key={tabName}
              _selected={{ borderBottom: "2px solid", borderColor: underlineColor, color: activeColor }}
              color={inactiveColor}
              _focus={{ boxShadow: "none" }}
            >
              {tabName}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <TabPanel>
            <ChangeNameTab
              initialName={userDetails?.name || ''}
              updateUserDetails={updateUserDetails}
            />
          </TabPanel>
          <TabPanel>
            <ChangeEmailTab
              initialEmail={userDetails?.login || ''}
              updateUserDetails={updateUserDetails}
            />
          </TabPanel>
          <TabPanel>
            <ChangePhoneNumberTab
              initialPhoneNumber={userDetails?.phoneNum || ''}
              updateUserDetails={updateUserDetails}
            />
          </TabPanel>
          <TabPanel>
            <ChangePasswordTab updateUserDetails={updateUserDetails} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

type UpdateData = {
  newLogin?: string;
  newPhoneNum?: string;
  newPassword?: string;
  nameDetails?: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
};

const useUpdateUserDetails = () => {
  const showToast = useCustomToast();

  const updateUserDetails = async (updateType: string, updateData: any): Promise<boolean> => {
    try {
      const response = await fetch(
        'https://inwise-node-functions2.azurewebsites.net/api/updateUserDetails?code=OwWQwvS8zA0ktbbZnmLJ3JaW0TmZRoV0lyjPA59BkQwZAzFueZU-IA%3D%3D',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            accessToken: localStorage.getItem('accessToken'),
            updateType,
            ...updateData,
          }),
        }
      );
      // TODO: add other response codes
      if (!response.ok) {
        throw new Error('Failed to update user details');
      }

      const result = await response.json();

      if (result.success) {
        showToast('Success', `Your ${updateType} has been updated successfully.`, 'success');
        return true;
      } else {
        throw new Error(result.message || `Failed to update ${updateType}`);
      }
    } catch (error) {
      console.error(`Error updating ${updateType}:`, error);
      showToast('Error', `An error occurred while updating your ${updateType}.`, 'error');
      return false;
    }
  };

  return updateUserDetails;
};


const useCustomToast = () => {
  const chakraToast = useToast();

  const showToast = (title: string, description: string, status: "info" | "warning" | "success" | "error") => {
    chakraToast({
      title,
      description,
      status,
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  };

  return showToast;
};

type UserDetails = {
  _id: string;
  name: string;
  login: string;
  accessToken: string;
  loginWith: string;
  phoneNum: string;
  googleAccessToken: string;
  microsoftAccessToken: string;
  googleMeetIntegrated: boolean;
  microsoftTeamsIntegrated: boolean;
  salesforceTokens: any,
  hubspotTokens: any,
  dynamicsTokens: any,
  zoomCredential: any
};

const useFetchUserDetails = () => {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const showToast = useCustomToast();

  const fetchUserDetails = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(
        'https://appwise-functions.azurewebsites.net/api/get-user-one?code=cByXzpreNWcPEbngEgD3g_E7RJV88SMcZPTqYCF_A_S5AzFu9pgM7A%3D%3D',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ accessToken: localStorage.getItem("accessToken") }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        setUserDetails(data.user);
      } else {
        throw new Error(data.error || 'Failed to fetch user details');
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError(error instanceof Error ? error.message : 'An unknown error occurred');
      showToast('Error', 'Failed to fetch user details. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch user details on mount
  useEffect(() => {
    fetchUserDetails();
  }, []);

  return { userDetails, isLoading, error };
};


type ChangeNameTabProps = {
  initialName: string;
  updateUserDetails: (updateType: string, updateData: any) => Promise<boolean>;
};

function ChangeNameTab({ initialName, updateUserDetails }: ChangeNameTabProps) {
  const values = [
    { nameString: 'First Name', name: 'firstName', initialValue: initialName.split(' ')[0] || '' },
    { nameString: 'Middle Name', name: 'middleName', initialValue: initialName.split(' ').slice(1, -1).join(' ') || '' },
    { nameString: 'Last Name', name: 'lastName', initialValue: initialName.split(' ').slice(-1)[0] || '', },
  ];

  const validateInput = (values: string[]) => {
    console.log(values);
    if (values[0] === '' || values[2] === '') {
      return { isValid: false, message: "First Name and Last Name are required" };
    }
    return { isValid: true, message: "" };
  }

  const updateName = async (newValues: any) => {
    const success = await updateUserDetails('name', { nameDetails: { ...newValues } });
    return success;
  }

  return (
    <EditDetailsTab values={values} validateInput={validateInput} updateUserDetails={updateName} />
  );
}

type ChangeEmailTabProps = { initialEmail: string; updateUserDetails: (updateType: string, updateData: any) => Promise<boolean>; };
function ChangeEmailTab({ initialEmail, updateUserDetails }: ChangeEmailTabProps) {

  const values = [{ nameString: 'Email', name: 'email', initialValue: initialEmail }];
  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  const validateInput = (values: string[]) => {
    if (values.length === 0 || values[0] === '') {
      return { isValid: false, message: "Email is required" };
    }
    else if (!emailRegex.test(values[0])) {
      return { isValid: false, message: "Please enter a valid email." };
    }
    return { isValid: true, message: "" };
  }

  const updateEmail = async (newValues: any) => {
    return await updateUserDetails('email', { newLogin: newValues['Email'] });
  }

  return (
    <EditDetailsTab values={values} validateInput={validateInput} updateUserDetails={updateEmail} />
  );
}

type ChangePhoneNumberTabProps = {
  initialPhoneNumber: string;
  updateUserDetails: (updateType: string, updateData: any) => Promise<boolean>;
};

function ChangePhoneNumberTab({ initialPhoneNumber, updateUserDetails }: ChangePhoneNumberTabProps) {

  const values = [{ nameString: 'Phone Number', name: 'phone', initialValue: initialPhoneNumber }];
  const validateInput = (values: string[]) => {
    if (values.length === 0 || values[0]?.trim().length < 8) {
      return { isValid: false, message: "Phone number is too short" };
    }
    // Add more validation here or let them anter whatever they want
    return { isValid: true, message: "" };
  }

  const updatePhoneNumber = async (newValues: any) => {
    return await updateUserDetails('phone', { newPhoneNum: newValues['Phone Number'] });
  }

  return (
    <EditDetailsTab values={values} validateInput={validateInput} updateUserDetails={updatePhoneNumber} />
  );
}

type ChangePasswordTabProps = { updateUserDetails: (updateType: string, updateData: any) => Promise<boolean>; };
function ChangePasswordTab({ updateUserDetails }: ChangePasswordTabProps) {

  const values = [
    { nameString: 'Current Password', name: 'currentPassword', initialValue: '', placeholder: 'Enter current password', isPassword: true },
    { nameString: 'New Password', name: 'newPassword', initialValue: '', placeholder: 'Enter new password (min 8 characters)', isPassword: true },
    { nameString: 'Confirm New Password', name: 'confirmNewPassword', initialValue: '', placeholder: 'Confirm new password', isPassword: true },
  ];

  const validateInput = (values: string[]) => {
    if (values[0] === '') {
      return { isValid: false, message: "Current password is required." };
    } else if (values[1].length < 8) {
      return { isValid: false, message: "New password must be at least 8 characters long." };
    } else if (values[1] !== values[2]) {
      return { isValid: false, message: "New passwords do not match." };
    }
    return { isValid: true, message: "" };
  }

  const updatePassword = async (newValues: any) => {
    const success = await updateUserDetails('password', { newPassword: newValues['New Password'] });
    return success;
  }

  return (
    <EditDetailsTab values={values} validateInput={validateInput} updateUserDetails={updatePassword} isPassword={true} />
  );
}

type EditableFieldProps = {
  label: string;
  value: string;
  placeholder?: string;
  variant?: 'password' | 'normal';
  isEditing?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  [x: string]: any;
};

function EditableField(props: EditableFieldProps) {
  const { label, value, placeholder, variant, isEditing, onChange, ...rest } = props;
  const isPassword = variant && variant === 'password';
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)
  return (
    <FormControl mb={8} {...rest} >
      <Flex flexDir={'column'} h={'100%'}>
        <FormLabel>{label}</FormLabel>
        <InputGroup>
          {isPassword && <InputLeftElement pointerEvents="none">
            <LockIcon color="gray.300" />
          </InputLeftElement>}
          <Input
            type={(!isPassword || show) ? 'text' : 'password'}
            placeholder={placeholder}
            value={(isPassword || isEditing || value) ? value : '-'}
            variant={isEditing ? 'outline' : 'unstyled'}
            ml={isEditing ? '0px' : '10px'}
            onChange={onChange}
          />
          {isPassword && <InputRightElement>
            <Button borderRadius={0} onClick={handleClick} rightIcon={show ? <PasswordVisibleIcon /> : <PasswordHiddenIcon />} pl='revert' fontWeight={'normal'} variant='light' />
          </InputRightElement>}
        </InputGroup>
      </Flex>
    </FormControl>
  );
}

interface EditableValues {
  nameString: string;
  name: string;
  initialValue: string;
  placeholder?: string;
}

type EditDetailsTabProps = {
  values: EditableValues[];
  isPassword?: boolean;
  validateInput?: (values: string[]) => { isValid: boolean, message: string };
  updateUserDetails: (updateData: any) => Promise<boolean>;
};

function EditDetailsTab({ values, isPassword = false, validateInput, updateUserDetails }: EditDetailsTabProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // TODO: Show spinner
  const showToast = useCustomToast();

  const initialValues = values.reduce((acc, { name, initialValue }) => {
    acc[name] = initialValue;
    return acc;
  }, {} as Record<string, string>);

  const [currentValues, setCurrentValues] = useState(initialValues);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const [invalidInputMessage, setInvalidInputMessage] = useState('No changes');
  function isFormValid(newValues: Record<string, string>) {

    console.log(newValues);
    if (Object.values(newValues).every(value => value === '')) {
      setInvalidInputMessage('Empty fields');
      return false;
    }
    else if (Object.values(newValues).every((value, index) => value === initialValues[values[index].name])) {
      setInvalidInputMessage('No changes');
      return false;
    }
    const { isValid, message } = validateInput ? validateInput(Object.values(newValues)) : { isValid: true, message: '' };
    setInvalidInputMessage(message);
    return isValid;
  }

  const handleChange = (field: string, value: string) => {
    setCurrentValues({ ...currentValues, [field]: value });
    setIsSubmitEnabled(isFormValid({ ...currentValues, [field]: value }));
  };

  const handleCancelEdit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsEditing(false);
    setCurrentValues(initialValues);
  }

  const handleSave = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);
    const success = await updateUserDetails(currentValues);
    if (success) {
      if (isPassword) {
        setCurrentValues(initialValues);
      }
      setIsEditing(false);
    } else {
      //theres already a toast in updateUserDetails maybe if we can get the error message from there
      //showToast("Submission Error", "Something went wrong, try again later or submit a report", "error");
      handleCancelEdit(event);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <form onSubmit={handleSave}>
        {values.map(({ name, nameString, placeholder }) => (
          <EditableField label={nameString} value={currentValues[name]} placeholder={placeholder} variant={isPassword ? 'password' : 'normal'} onChange={(e) => handleChange(name, e.target.value)} isEditing={isEditing} key={name} />
        ))}
        {!isPassword ? <Flex mb={4} justifyContent="flex-end">
          {!isEditing ? (
            <BackButton onClick={() => setIsEditing(true)} buttonText={<><EditIcon /> <Text ml={2}>Edit</Text></>} w={'100px'} />
          ) : (
            <>
              <BackButton mr={2} onClick={handleCancelEdit} buttonText={'Cancel'} w={'100px'} />
              <Tooltip label={isSubmitEnabled ? '' : invalidInputMessage} shouldWrapChildren placement='top'>
                <NextButton onClick={handleSave} buttonText={'Save'} w={'100px'} isDisabled={isLoading || !isSubmitEnabled} />
              </Tooltip>
            </>
          )}
        </Flex> :
          <Flex mb={4} mt={16} justifyContent="flex-end">
            <Tooltip label={isSubmitEnabled ? '' : invalidInputMessage} shouldWrapChildren placement='top'>
              <NextButton onClick={handleSave} buttonText={'Submit'} w={'100px'} isDisabled={isLoading || !isSubmitEnabled} />
            </Tooltip>
          </Flex>
        }
      </form>
    </Box >
  );
}
const ViewIntegrationsTab = () => {
  const { userDetails, isLoading: userLoading, error } = useFetchUserDetails();
  const [isGoogleIntegrated, setGoogleIntegrated] = useState(false);
  const [isMicrosoftIntegrated, setMicrosoftIntegrated] = useState(false);
  const [isGoogleMeetIntegrated, setGoogleMeetIntegrated] = useState(false);
  const [isMicrosoftTeamsIntegrated, setMicrosoftTeamsIntegrated] = useState(false);
  const [isSalesforceIntegrated, setSalesforceIntegrated] = useState(false);
  const [isZoomIntegrated, setZoomIntegrated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const integrateCalendarUrl = "https://sandwise.azurewebsites.net/api/integrate-calendar-service?code=uIgkckKwWH4GxNg3TZblLoe-CCAHrKa18exxtlGERVDFAzFumOru2Q%3D%3D"; // TODO move to appwise
  const sfTokenExchangeUrl = "https://appwise-functions.azurewebsites.net/api/login-salesforce-code?code=3eoP2nE1My0r0i89KTzgJn7jldrMcOU1uosopX67x6HjAzFu0taCtw==";
  const zoomOauthCredentialUrl = "https://appwise-functions.azurewebsites.net/api/login-zoom-code?code=-I2VLgOF3cqe5E67EJ5f4GlcIrBYySCAN0QXSYdSZS6BAzFuSxcPsA==";
  useEffect(() => {
    if (userDetails) {
      setGoogleIntegrated(userDetails.loginWith === "Google");
      setMicrosoftIntegrated(userDetails.loginWith === "Microsoft");
      setGoogleMeetIntegrated(userDetails.googleMeetIntegrated);
      setMicrosoftTeamsIntegrated(userDetails.microsoftTeamsIntegrated);
      setSalesforceIntegrated(Date.parse(userDetails.salesforceTokens?.accessTokenExpiration) > Date.now());
      setZoomIntegrated(userDetails.zoomCredential?.id);
    }
  }, [userDetails]);

  const generateCodeVerifier = () => {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, dec => ("0" + dec.toString(16)).slice(-2)).join("");
  };

  const generateCodeChallenge = async (codeVerifier: string) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const digest = await window.crypto.subtle.digest('SHA-256', data);
    return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  };

  const handleIntegration = async (
    serviceType: "Google" | "Microsoft" | "GoogleMeet" | "MicrosoftTeams" | "Salesforce" | "Zoom"
  ) => {
    setLoading(true);
    try {
      const redirectUri = "https://www.sellwisely.ai/settings";
      // const redirectUri = "https://localhost:3000/settings";
      const state = Math.random().toString(36).substring(7);
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallenge(codeVerifier);
      const authUrl =
        serviceType === "Google"
          ? `https://accounts.google.com/o/oauth2/v2/auth?client_id=488689976032-7o0um6cjmonn0pcj6cdtlfseg383tsie.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(
            redirectUri
          )}&response_type=code&scope=https://www.googleapis.com/auth/calendar&state=${state}&prompt=consent`
          : serviceType === "Microsoft"
            ? `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=d8c3d816-86ca-406e-8f95-5f602c5d8f1c&redirect_uri=${encodeURIComponent(
              redirectUri
            )}&response_type=code&scope=Calendars.Read&state=${state}&prompt=consent`
            : serviceType === "GoogleMeet"
              ? `https://accounts.google.com/o/oauth2/v2/auth?client_id=488689976032-7o0um6cjmonn0pcj6cdtlfseg383tsie.apps.googleusercontent.com&redirect_uri=${encodeURIComponent(
                redirectUri
              )}&response_type=code&scope=https://www.googleapis.com/auth/calendar.events&state=${state}&prompt=consent`
              : serviceType === "Salesforce"
                ? `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9VMBZCsTL9hkm2ojry8u45qZdmPsmameY9yG2TRNaFA7PNr91t9W0soGDqhvIkgcrdVu20MaE0DO0B0aP&redirect_uri=${encodeURIComponent(redirectUri)}&code_challenge=${codeChallenge}&code_challenge_method=S256&scope=offline_access id api`
                : serviceType === "MicrosoftTeams"
                  ? `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=d8c3d816-86ca-406e-8f95-5f602c5d8f1c&redirect_uri=${encodeURIComponent(
                    redirectUri
                  )}&response_type=code&scope=OnlineMeetings.ReadWrite&state=${state}&prompt=consent`
                  : `https://zoom.us/oauth/authorize?response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&client_id=_4o2V5VJTpCT1geZyywy6Q`;

      const authCode = await openAuthPopup(authUrl);
      if (authCode) {
        await completeIntegration(authCode, serviceType, codeVerifier);
      } else {
        throw new Error("Authorization code not received");
      }
    } catch (error) {
      toast({
        title: "Integration error",
        description: `Failed to integrate with ${serviceType}. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const openAuthPopup = (url: string): Promise<string | null> => {
    return new Promise((resolve) => {
      const width = 500;
      const height = 600;
      const left = window.innerWidth / 2 - width / 2;
      const top = window.innerHeight / 2 - height / 2;
      const authWindow = window.open(
        url,
        "OAuth Popup",
        `width=${width},height=${height},top=${top},left=${left}`
      );
      let isCrossOrigin = false;

      const authListener = (event: MessageEvent) => {
        if (event.origin !== window.location.origin || !event.data.authCode) return;
        window.removeEventListener("message", authListener);
        authWindow?.close();
        resolve(event.data.authCode);
      };

      window.addEventListener("message", authListener);

      const timer = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(timer);
          window.removeEventListener("message", authListener);
          resolve(null);
        }

        try {
          const popupUrl = authWindow?.location.href;
          if (popupUrl && popupUrl.includes("code=")) {
            const urlParams = new URLSearchParams(authWindow.location.search);
            const authCode = urlParams.get("code");
            if (authCode) {
              window.removeEventListener("message", authListener);
              clearInterval(timer);
              authWindow.close();
              resolve(authCode);
            }
          }
        } catch (error) {
          // Ignore cross-origin errors until the redirect URI is reached
        }
      }, 500);
    });
  };

  const completeIntegration = async (
    code: string,
    serviceType: "Google" | "Microsoft" | "GoogleMeet" | "MicrosoftTeams" | "Salesforce" | "Zoom",
    codeVerifier?: string,
  ) => {
    try {
      if (["Google", "Microsoft", "MicrosoftTeams", "GoogleMeet"].includes(serviceType)) {
        await fetch(integrateCalendarUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: userDetails?._id, authCode: code, serviceType }),
        });

        if (serviceType === "Google") {
          setGoogleIntegrated(true);
        } else if (serviceType === "Microsoft") {
          setMicrosoftIntegrated(true);
        } else if (serviceType === "GoogleMeet") {
          setGoogleMeetIntegrated(true);
        } else if (serviceType === "MicrosoftTeams") {
          setMicrosoftTeamsIntegrated(true);
        }
      } else if (serviceType === "Salesforce") {
        await fetch(sfTokenExchangeUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code, codeVerifier, userId: userDetails._id }),
        });
        setSalesforceIntegrated(true);
      } else { // Zoom
        await fetch(zoomOauthCredentialUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code, wiseAccessToken: localStorage.getItem('accessToken') }),
        });
        setZoomIntegrated(true)
      }


      toast({
        title: `${serviceType} Integration`,
        description: `${serviceType} integrated successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Integration error",
        description: `Failed to complete ${serviceType} integration. Please try again.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleOAuthRedirect = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code && window.opener) {
        window.opener.postMessage({ authCode: code }, window.location.origin);
        window.close();
      }
    };

    handleOAuthRedirect();
  }, []);

  if (userLoading) {
    return <Text>Loading user data...</Text>;
  }

  if (error) {
    return <Text color="red.500">Error: {error}</Text>;
  }

  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Tabs variant="unstyled">
        <TabList>
          <Tab
            _selected={{ color: '#2B3674', borderBottom: '2px solid #2B3674', fontWeight: 'bold' }}
            color="#A3AED0"
            fontFamily="DM Sans"
            _focus={{ boxShadow: 'none' }}
          >
            Calendar
          </Tab>
          <Tab
            _selected={{ color: '#2B3674', borderBottom: '2px solid #2B3674', fontWeight: 'bold' }}
            color="#A3AED0"
            fontFamily="DM Sans"
            _focus={{ boxShadow: 'none' }}
          >
            Meeting Clients
          </Tab>
          <Tab
            _selected={{ color: '#2B3674', borderBottom: '2px solid #2B3674', fontWeight: 'bold' }}
            color="#A3AED0"
            fontFamily="DM Sans"
            _focus={{ boxShadow: 'none' }}
          >
            CRM
          </Tab>
          <Tab
            _selected={{ color: '#2B3674', borderBottom: '2px solid #2B3674', fontWeight: 'bold' }}
            color="#A3AED0"
            fontFamily="DM Sans"
            _focus={{ boxShadow: 'none' }}
          >
            Other
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <VStack spacing={4} align="stretch">
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Google Calendar</Text>
                <Switch
                  colorScheme="green"
                  isChecked={isGoogleIntegrated}
                  isDisabled={isLoading || isGoogleIntegrated}
                  onChange={() => handleIntegration("Google")}
                  sx={{
                    'span.chakra-switch__track': {
                      backgroundColor: isGoogleIntegrated ? 'brand.500' : 'gray.300'
                    }
                  }}
                />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Microsoft Calendar</Text>
                <Switch
                  colorScheme="green"
                  isChecked={isMicrosoftIntegrated}
                  isDisabled={isLoading}
                  onChange={() => handleIntegration("Microsoft")}
                  sx={{
                    'span.chakra-switch__track': {
                      backgroundColor: isMicrosoftIntegrated ? 'brand.500' : 'gray.300'
                    }
                  }}
                />
              </Flex>
            </VStack>
          </TabPanel>

          <TabPanel>
            <VStack spacing={4} align="stretch">
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Google Meet</Text>
                <Switch
                  colorScheme="green"
                  isChecked={isGoogleMeetIntegrated}
                  isDisabled={isLoading || isGoogleMeetIntegrated}
                  onChange={() => handleIntegration("GoogleMeet")}
                  sx={{
                    'span.chakra-switch__track': {
                      backgroundColor: isGoogleIntegrated ? 'brand.500' : 'gray.300'
                    }
                  }}
                />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Microsoft Teams</Text>
                <Switch
                  colorScheme="green"
                  isChecked={isMicrosoftTeamsIntegrated}
                  isDisabled={isLoading || isMicrosoftTeamsIntegrated}
                  onChange={() => handleIntegration("MicrosoftTeams")}
                  sx={{
                    'span.chakra-switch__track': {
                      backgroundColor: isMicrosoftIntegrated ? 'brand.500' : 'gray.300'
                    }
                  }}
                />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Zoom</Text>
                <Switch
                  colorScheme="green"
                  isChecked={false} // Update this logic if Zoom integration status is available
                  isDisabled={isLoading} // Adjust as needed
                  onChange={() => handleIntegration("Zoom")} // Implement Zoom integration logic in handleIntegration
                  sx={{
                    'span.chakra-switch__track': {
                      backgroundColor: 'gray.300' // Adjust color based on integration status
                    }
                  }}
                />
              </Flex>
            </VStack>
          </TabPanel>

          <TabPanel>
            <VStack spacing={4} align="stretch">
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Salesforce</Text>
                <Switch
                  colorScheme="green"
                  isChecked={isSalesforceIntegrated}
                  isDisabled={isLoading || isSalesforceIntegrated}
                  onChange={() => handleIntegration("Salesforce")}
                  sx={{
                    'span.chakra-switch__track': {
                      backgroundColor: isSalesforceIntegrated ? 'brand.500' : 'gray.300'
                    }
                  }}
                />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">HubSpot</Text>
                <Switch colorScheme="gray" isDisabled />
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Dynamics</Text>
                <Switch colorScheme="gray" isDisabled />
              </Flex>
            </VStack>
          </TabPanel>

          <TabPanel>
            <VStack spacing={4} align="stretch">
              <Flex alignItems="center" justifyContent="space-between" p={4} mb={2} borderWidth={1} borderRadius="md">
                <Text color="#2B3674" fontFamily="DM Sans">Apollo</Text>
                <Switch colorScheme="gray" isDisabled />
              </Flex>
            </VStack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
