import {
  Collapse,
  Flex,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CompanySearchResult, ProspectSearchResult } from 'views/prospects/Prospect';
import { BulletSection } from 'views/prospects/components/AboutContact';
import SelectProduct from "../../../common/SelectProduct";
import { SearchCompany } from '../SearchCompany';
import { useState } from 'react';

export function ProspectInfoSection(props: {
  prospect: ProspectSearchResult
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { prospect } = props;
  const headerColor = useColorModeValue('navy.800', 'white')
  if (!prospect.education && !prospect.skills) return null;
  return (
    <Card p='30px' m='10px' >
      <Text color={textColor} fontSize='xl' fontWeight='700' mb='20px' >
        Prospect Info
      </Text>
      <Flex width={'100%'} justify='space-between' direction={'column'}>
        {
          prospect.experience && <Flex flexDir={'column'} flexGrow={1} flexBasis={0}>
            <Text fontWeight={'bold'} color={textColor}>Recent experience</Text>
            <UnorderedList fontSize={'md'} listStyleType='none' ml={'0px'}>
              {prospect.experience.sort((a, b) => {
                const dateA = new Date(a.start_date);
                const dateB = new Date(b.start_date);
                return dateB.getTime() - dateA.getTime();
              }).slice(0, 3).map((exp, index) => (
                <ListItem key={index} >
                  <Text color={textColor}>{exp.title.name} at {exp.company.name} </Text>
                  <Text color='secondaryGray.600' fontSize='sm' fontWeight='400'>{exp.start_date?.replace('-', '/')} - {exp.end_date ? exp.end_date?.replace('-', '/') : 'present'}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
        }
        {prospect.skills && <BulletSection list={prospect.skills.slice(0, 5)} header={'Skills'} color={headerColor} mt='14px' />
        }
      </Flex>
    </Card>
  );
}

export default function AddToDeal(props: {
  prospect: ProspectSearchResult,
  variant: 'createDeal' | 'addProspect' | 'futureMeetings',
  onReturn: () => void,
  onConfirm: (prospect: ProspectSearchResult, company: CompanySearchResult) => void;
}) {
  const { prospect, variant, onReturn, onConfirm } = props
  const [showSearchCompany, setShowSearchCompany] = useState(false)
  if (!prospect) return null
  const [selectedCompany, setSelectedCompany] = useState(null)

  function onSelectedCompany(selectedCompany: CompanySearchResult): void {
    setSelectedCompany(selectedCompany);
    setShowSearchCompany(false);
  }

  function handleCancel(): void {
    setShowSearchCompany(false);
  }
  const textColor = useColorModeValue('navy.700', 'white');
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <Collapse in={showSearchCompany} animateOpacity>
        <SearchCompany onSelectCompany={onSelectedCompany} onReturn={handleCancel} mb={'20px'} />
      </Collapse>
      <Card>
        {!isLoading ?
          <Flex direction='column' w='100%' >
            <Flex w='100%'>
              <SelectProduct
                prospect={prospect}
                variant={variant}
                companyResult={selectedCompany}
                onNext={onConfirm}
                onReturn={onReturn}
                onSearchCompany={function (): void { setShowSearchCompany(true); }}
                onLoading={setIsLoading} />
              <ProspectInfoSection
                prospect={prospect} />
            </Flex >
          </Flex > :
          <Flex justify='start' align='center' h={'570px'} flexDir={'column'} pt={'30px'}>
            <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' mb={'20px'} />
            <Text color={textColor} fontWeight={'700'}>{variant == 'createDeal' ? 'Creating Deal...' : 'Creating Prospect...'}</Text>
          </Flex>}
      </Card >
    </>
  );
}
