import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Select } from '@chakra-ui/react';
import { Deal } from 'views/deals/Deal';
import { useState } from 'react';
import { BackButton, NextButton } from 'views/common/common';

export default function SelectDealModal(props: { isOpen: boolean, onClose: () => void, deals: Deal[], onDealSelected: (deal: Deal | 'create') => void, isLoading: boolean }) {
  const { isOpen, onClose, deals, onDealSelected, isLoading } = props;
  const [selectedDealId, setSelectedDealId] = useState<string>('');

  const handleDealChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDealId(event.target.value);
  };

  const handleSave = () => {
    const selectedDeal = deals.find(deal => deal._id === selectedDealId);
    onDealSelected(selectedDeal || 'create');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select a Deal</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Select placeholder="Select a deal" value={selectedDealId} onChange={handleDealChange}>
            {deals.map((deal, index) => (
              <option key={index} value={deal._id}>{deal.name}</option>
            ))}
            <option value="create">Create a new Deal</option>
          </Select>
        </ModalBody>
        <ModalFooter>
          <BackButton
            mr={2}
            onClick={onClose}
            buttonText={'Cancel'}
            w={'100px'}
            disabled={isLoading}
            _hover={{ bg: 'gray.200', opacity: 1 }}
          />
          <NextButton
            isDisabled={!selectedDealId || isLoading}
            onClick={handleSave}
            buttonText={'Next'}
            ml='10px'
            w={'100px'}
            isLoading={isLoading}
            _hover={{ bg: 'blue.500', opacity: 1 }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
