import { Modal, ModalOverlay, ModalContent, ModalBody, Flex, Spinner, Text, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BackButton, NextButton } from 'views/common/common';
import { ProspectSearchResult } from 'views/prospects/Prospect';
import SearchAndCreate from 'views/common/SearchAndCreate';

export default function SearchAttendeeByEmailModal(props: { isOpen: boolean, onClose: () => void, attendeeEmail: string, meetingId?: string, onCreated: () => void }) {
  const { isOpen, onClose, attendeeEmail, meetingId, onCreated } = props;
  const [selectedAttendee, setSelectedAttendee] = useState<ProspectSearchResult>(null);
  const [prospectSearchResults, setProspectSearchResults] = useState<ProspectSearchResult[]>()
  const [showManualSearch, setShowManualSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const searchByEmailUrl = 'https://inwise-node-functions2.azurewebsites.net/api/pdl-enrichment-search?code=nVdFLOMagdhFcOyqPPGQ1klX-eK5H0jTqbydqhr6hCVPAzFu8rCxww=='
  const handleBack = () => {
    if (showManualSearch) {
      setShowManualSearch(false);
      if (!(prospectSearchResults?.length > 0)) {
        onClose()
      }
    }
    else {
      onClose()
    }
  }

  const handleSearchManually = () => {
    setShowManualSearch(true)
  }

  useEffect(() => {
    setProspectSearchResults(null)
    setShowManualSearch(false)
    if (!attendeeEmail) { return; }
    fetch(searchByEmailUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ command: 'people', data: { 'email': attendeeEmail } }),
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setProspectSearchResults(data)
        console.log(data, data.length)
      })
      .catch((error: Error) => {
        console.log(error);
        //do stuff here
      });
  }, [attendeeEmail]);


  const textColor = useColorModeValue('navy.700', 'white');
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="900px" borderRadius={'30px'}>
        {(!showManualSearch && prospectSearchResults && prospectSearchResults.length > 0) &&
          <>
            <ModalBody w='100%' p={'20px'}>
              <SearchAndCreate
                variant={'futureMeetings'}
                prospects={prospectSearchResults}
                onBack={handleBack}
                onLoading={() => { setIsLoading(true) }}
                onCreated={onCreated}
                meetingId={meetingId} />

            </ModalBody>
            {!isLoading && <Flex p={'20px'} w={'100%'} justifyContent={'end'}>
              <Text color={textColor} fontSize='lg' fontWeight='500' px={'20px'}>Or search for someone else </Text>

              <NextButton
                onClick={handleSearchManually}
                buttonText={'Go'} ml='10px' w={'70px'} h={'40px'} />
            </Flex>}
          </>
        }
        {!showManualSearch && prospectSearchResults && !(prospectSearchResults.length > 0) &&
          <>
            <Text color={textColor} fontSize='lg' fontWeight='500' pt={'20px'} px={'20px'}>Couldn't find prospect from email. Try searching manually</Text>
            <Flex p={'20px'} w={'100%'} >
              <NextButton
                onClick={handleSearchManually}
                buttonText={'Go'} ml='10px' w={'100px'} />
              <BackButton buttonText={'Cancel'} onClick={handleBack} />
            </Flex>
          </>}
        {!showManualSearch && !prospectSearchResults &&

          <Flex w={'100%'} h={'200px'} justifyContent='start' alignItems={'center'} flexDir={'column'} pt={'30px'}>
            <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' mb={'20px'} />
            <Text color={textColor} fontWeight={'700'}>Searching for prospects...</Text>
          </Flex>
        }
        {showManualSearch &&
          <SearchAndCreate variant='futureMeetings' onBack={handleBack} onCreated={onCreated} onLoading={() => { setIsLoading(true) }} meetingId={meetingId} />
        }
      </ModalContent>
    </Modal>
  );
}

