import { Prospect } from "views/prospects/Prospect";

export interface DealSummary {
  _id: string;
  wiseCompanyId: string,
  name: string;
  dealPhase: DealPhase;
  customerSentiment: number;
  timeToClose: number;
  avgPurchaseValue: number;
  likelihoodToClose: number;
  summary: string;
  daysSinceLastInteraction: number;
}

export interface ActionItem {
  item: string,
  owner: string,
  deadline: string
}

export interface Deal extends DealSummary {
  accountId: string,
  dealCardInfo: dealCardInfo,
  actionItems: Insight[],
  recommendedActionItems: string[],
  dealProgressContent: Insight[],
  dealSummaryContent: DealSummaryContent,
  qualificationContent: QualificationChecklistContent,
  meetings: Meeting[],
  productInsightsContent: ProductInsightsContent
  talkingPoints: TalkingPoints,
  stakeHolders: Prospect[],
  stakeholdersList: Prospect[],
  prospectName: string,
  prospectSumm: string,
  companySumm: string,
  futureMeetings: Meeting[],
  account: any,
}

export interface dealCardInfo {
  sentiment: Insight,
  size: Insight,
  likelihoodToClose: Insight,
  estimatedClose: Insight,
  stage: Insight,
  summary: Insight,
  daysSinceLastInteraction: Insight,
}

export interface ProductInsightsContent {
  customerExpectations: Insight[],
  featureRequests: Insight[]
}
export interface DealSummaryContent {
  executiveSummary: Insight[],
  businessContext: Insight[],
  complications: Insight[],
  solutionOverview: Insight[],
  targetOutcome: Insight[],
}

export interface DealProgressContent {
  meetingId: string,
  name: string,
  oneLineSummary: string,
  date: string
}

export interface QualificationChecklistContent {
  situation: Insight[],
  problem: Insight[],
  impact: Insight[],
  criticalEvent: Insight[],
  decision: Insight[],
}

export interface TalkingPoints {
  benefitsOverCompetition: Insight[],
  howToWin: Insight[],
  risk: Insight[]
}

export interface Insight {
  insightId: string,
  content: any,
}

export interface DealProgressItem {
  content: { date: string, meetingId: string, name: string, oneLineSummary: string },
  insightId: string,
}

export interface Meeting {
  _id: string,
  wiseCompanyId: string,
  dealId: string,
  deal: Deal,
  name: string,
  date: string,
  startTime: string,
  endTime: string,
  meetingLink: string,
  agenda: Insight[],
  actionItems: Insight[],
  attendees: any[],
  attendeesEmails: string[],
  attendeesByCompany: { customer: string[], other: string[], provider: string[] },
  generatedSummary: string[],
  googleMeetingId: string,
  meetingTranscriptId: string,
  metadata: string,
  isNext: boolean,
  isLast: boolean,
  sentimentAnalysis: any,
  meetingHighlights: any,
  oneLineSummary: string,
  botId: string,
  unscheduledAt: string,
}

export enum DealPhase {
  Qualification = 'Qualification',
  Demo = 'Demo',
  Proposal = 'Proposal',
  Negotiation = 'Negotiation',
  Closed = 'Closed',
}

export interface LocationProps {
  state: {
    dealSummaries: Deal[];
    onDealSummariesUpdated: Function;
  }
}

export interface GetGlobalActionsResponse {
  message: any,
  deals: Deal[]
}

export interface GetCollectionItemResponse {
  item: any,
  message: any
}

export interface createDealResponse {
  message: any;
  newDealResponse: { acknowledged: boolean, insertedId: string }
  newProspectResponse: { acknowledged: boolean, insertedId: string }
  accountId: string
}
