import { Flex, Icon, Text, Textarea, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { RecordIcon, CloseIcon, StopIcon } from 'components/icons/Icons';
import { useState, useRef, useEffect } from 'react';
import { BackButton, NextButton } from 'views/common/common';


const AudioRecorder = (props: {
  header: string,
  buttonText: string,
  notesPlaceholderText?: string,
  dealId?: string, // move to parent and have a callback to process the audio here instead
}) => {

  const { header, buttonText, notesPlaceholderText, dealId } = props;
  const [isRecording, setIsRecording] = useState(false);
  const [notesText, setNotesText] = useState<string | undefined>();
  const [audioFiles, setAudioFiles] = useState<{ blob: Blob, url: string }[]>([]);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const getSasUrl = "https://inwise-node-functions2.azurewebsites.net/api/get-blob-storage-sas?code=nVdFLOMagdhFcOyqPPGQ1klX-eK5H0jTqbydqhr6hCVPAzFu8rCxww%3D%3D";
  const processVoiceMemoUrl = "https://inwise-node-functions2.azurewebsites.net/api/transcribe-voice-note?code=nVdFLOMagdhFcOyqPPGQ1klX-eK5H0jTqbydqhr6hCVPAzFu8rCxww%3D%3D"

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const recorder = new MediaRecorder(stream);
    const chunks: BlobPart[] = [];
    recorder.ondataavailable = (event) => {
      chunks.push(event.data);
    };

    recorder.onstop = () => {
      const audioBlob = new Blob(chunks, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);

      setAudioFiles((prev) => [
        ...prev,
        { blob: audioBlob, url: audioUrl }
      ]);

      setIsRecording(false);
    };

    recorder.start();
    setMediaRecorder(recorder);
    setIsRecording(true);
  };

  const handleAudioPlay = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track: { stop: () => any; }) => track.stop());
      setIsRecording(false);
    }
  };

  const handleCancel = () => {
    setIsRecording(false);
    setAudioFiles([]);
    setNotesText('');

  };

  const [currentDuration, setCurrentDuration] = useState<number>(0);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRecording) {
      interval = setInterval(() => {
        setCurrentDuration((prev) => prev + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setCurrentDuration(0);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  const handleSave = async () => {
    setIsLoading(true);
    const formData = new FormData();
    const storageUrls = [];
    try {
      // Step 1: Get SAS URL for each audio file
      const res = await fetch(getSasUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileNamePrefix: dealId,
          fileTypeExtension: "wav",
          sasCount: audioFiles.length // Number of SAS tokens to generate
        })
      });
      const data = await res.json();
      console.log('data:', data);
      const sasResults = data.urls as { sasUrl: string, blobUrl: string }[];

      // Step 2: Upload the file to Azure Blob Storage using the SAS URL
      for (const [index, file] of audioFiles.entries()) {
        const { sasUrl, blobUrl } = sasResults[index];
        const uploadUrl = `${blobUrl}?${sasUrl.split('?')[1]}`; // Append SAS token to the URL

        const response = await fetch(uploadUrl, {
          method: 'PUT',
          body: file.blob,
          headers: {
            'x-ms-blob-type': 'BlockBlob',  // Required to specify the blob type
          },
        });
        if (response.ok) {
          console.log('Successfully uploaded blob:', index);
          console.log('response:', response);
          storageUrls.push(blobUrl);
        }
        else {
          console.error('Error uploading blob:', response.statusText);
        }
      }
      // Step 3: Process the voice memo
      const processResponse = await fetch(processVoiceMemoUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dealId: dealId,
          storageUrls: storageUrls,
          notes: notesText
        })
      });

      if (processResponse.ok) {
        console.log('Successfully processed voice memo');
        console.log('response:', processResponse);
        toast({
          title: "Success",
          description: "Voice memo processed successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // Clear the form
        setAudioFiles([]);
        setNotesText('');
      }
      else {
        console.error('Error processing voice memo:', processResponse.statusText);
        toast({
          title: "Error",
          description: "Failed to process voice memo.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (e) {
      console.log('Error:', e);
      toast({
        title: "Error",
        description: "An error occurred during the process.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }

  };

  function removeTrack(index: number): void {
    console.log('removing track', index);
    setAudioFiles((prev) => prev.filter((_, i) => i !== index));
  }

  const titleColor = useColorModeValue('navy.700', 'white');
  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');

  //add loading state
  return (
    <Card mt='20px' p='20px'>
      <Flex flexDir={'column'} flexGrow={1} flexBasis={0}>
        <Flex align='center' textAlign='center' pb='10px'>
          <Text fontWeight={'bold'} color={titleColor}>{header}</Text>
        </Flex>
        {audioFiles.length < 5 && <Flex alignItems={'center'} mb='20px' mt='10px'>
          <Flex
            as="button"
            onClick={isRecording ? stopRecording : startRecording}
            color={textColorLight}
            _hover={{ color: textColorTertiary, bg: 'brand.800' }}
            bg={'brand.900'} borderRadius={'50%'} p={'8px'} boxShadow={'0 4px 8px 0 rgba(0,0,0,0.3)'} w={'40px'} h={'40px'}
          >
            <Icon as={isRecording ? StopIcon : RecordIcon} w='25px' h='25px' color={'white'} />
          </Flex>
          <Text ml='10px' color={textColorTertiary}>{isRecording ? new Date(currentDuration * 1000).toISOString().substring(11, 19) : buttonText}</Text>
        </Flex>}
        {audioFiles?.length > 0 && (
          <Flex flexDir="column" w="100%">
            {audioFiles?.map((url, index) => (
              <Flex key={index} alignItems="center" mb="10px">
                <audio
                  src={url.url}
                  ref={audioRef}
                  controls
                  style={{ width: '100%', height: '40px', border: 'none' }}
                  onPlay={handleAudioPlay}
                />
                <Flex
                  as="button"
                  onClick={() => removeTrack(index)}
                  color={textColorTertiary}

                  borderRadius={'50%'} w={'20px'} h={'20px'} ml="10px"
                >
                  <Icon as={CloseIcon} w='20px' h='20px' color={textColorTertiary} _hover={{ color: 'brand.800' }} />
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
        <Text color={titleColor} fontSize='md' mt='20px'>Notes:</Text>
        <Textarea
          value={notesText}
          onChange={(e) => setNotesText(e.target.value)}
          variant='outline'
          placeholder={notesPlaceholderText}
          mt='10px'
          p={2}
          fontSize='md'
        />
        {audioFiles?.length > 0 && <Flex justifyContent="flex-end" mt={4} w={'100%'}>
          <BackButton mr={2} onClick={handleCancel} buttonText={'Cancel'} w={'100px'} />
          <NextButton onClick={handleSave} buttonText={isLoading ? 'Submitting...' : 'Submit'} w={'100px'} isDisabled={isLoading} />
        </Flex>}
      </Flex>
    </Card>
  );
};

export default AudioRecorder;
