import { useEffect } from "react";
import { Link as ReactLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useClipboard,
  useToast,
  UnorderedList,
  OrderedList,
  ListItem,
  Image,
  Stack,
  LinkBox,
  LinkOverlay,
  Link,
  Divider,
  Avatar,
  List,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";

import "react-calendar/dist/Calendar.css";
import "assets/css/MiniCalendar.css";
import Card from "components/card/Card";
import { VSeparator } from "components/separator/Separator";
import { Meeting } from "views/deals/Deal";
import { Prospect } from "views/prospects/Prospect";
import CopyIcon from "assets/svg/copy-light.svg";
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import MeetingSummaryCard from "views/meeting/components/MeetingSummaryCard";
import { MeetingSummary } from "./MeetingCard";
import InsightSectionWithFeedback from 'components/feedback/InsightSectionWithFeedback';

function TalkingPointsSection(props: { prospect: Prospect }) {
  const { prospect } = props;
  const talkingPointsHeader = `Talking points for ${prospect.name.split(" ")[0]}`
  const textColor = useColorModeValue("#2B3674", "white");
  const textColorSecondary = useColorModeValue("#44546F", "white");
  if (prospect.talkingPoints && prospect.talkingPoints.length > 0) {
    return (
      <Flex w={"100%"} fontSize={"16px"} mt={"10px"}>
        <Stack
          h="100%"
          w="100%"
          alignItems={"start"}
          color={textColorSecondary}
        >
          <InsightSectionWithFeedback insights={prospect.talkingPoints} sectionHeader={talkingPointsHeader} />
        </Stack>
      </Flex >
    );
  }
  return null;
}
export function getFormattedDateTime(meeting: Meeting) {
  if (!meeting) return null;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const originalDate = moment(meeting.date + ' ' + meeting.startTime, 'YYYY-MM-DD HH:mm:ss Z');
  const formattedDate = momentTimezone(originalDate).tz(userTimeZone).format('MMMM DD, YYYY, hh:mm A');
  return formattedDate;
}

function SuggestedTacticsSection(props: { prospect: Prospect }) {
  const { prospect } = props;
  const header = `Suggested tactics for ${prospect.name.split(" ")[0]}`
  const textColor = useColorModeValue("#2B3674", "white");
  const textColorSecondary = useColorModeValue("#44546F", "white");
  const roleActions = prospect?.prospectSegmentation?.roleAction || prospect?.prospectSegmentation?.role_action;
  if (roleActions && roleActions?.length > 0) {
    return (
      <Flex w={"100%"} fontSize={"16px"} mt={"10px"}>
        <Stack
          h="100%"
          w="100%"
          alignItems={"start"}
          color={textColorSecondary}
        >
          <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px' >
            <Flex align='center' textAlign='center' pb='10px'>
              <Text fontWeight={'bold'} color={textColor}>{header}</Text>
            </Flex>
            <UnorderedList fontSize='16px'>
              {roleActions.map((item, index) => (
                <Flex
                  key={index}
                  flexDir='row'
                  flexGrow={1}
                  flexBasis={0}
                  mr='32px'
                  justifyContent='space-between'
                  position='relative'
                >
                  <ListItem ml={2}>{item}</ListItem>
                </Flex>
              ))}
            </UnorderedList>
          </Flex>
        </Stack>
      </Flex >
    );
  }
  return null;
}

export function MeetingDetailsCard(props: {
  prospect: Prospect,
  variant: "dealOverview" | "prospectOverview",
  meetingData: Meeting,
  lastMeetingId: string,
  [x: string]: any;
}) {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const linkColor = useColorModeValue("#0F738C", "white");
  const nameColor = useColorModeValue('secondaryGray.900', 'white');
  const roleColor = useColorModeValue('secondaryGray.600', 'white');
  const { onCopy, hasCopied } = useClipboard("202-432-2990"); // TODO: review this
  const toast = useToast();
  const {
    prospect,
    variant,
    meetingData,
    lastMeetingId,
    ...rest
  } = props;

  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copied!",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
    }
  }, [hasCopied, toast]);

  function MeetingOverviewLink(props: { meetingId: string }) {
    const { meetingId } = props
    return (
      <Link
        as={RouterLink}
        to={`/meetings/overview/${meetingId}`}
        color={linkColor}>
        Link
      </Link>
    )
  }
  const agendaText = meetingData?.date && moment(new Date()).isAfter(meetingData.date) ? "Topics discussed" : "Agenda";
  let meetingLink = null
  try { meetingLink = new URL(meetingData?.meetingLink) } catch (e) { console.log(e) }
  return (
    <LinkBox as="article" w="100%" h={'100%'}>
      {meetingData &&
        <LinkOverlay
          as={RouterLink}
          to={{
            pathname: `/meetings/overview/${meetingData._id}`,
          }}
        />
      }
      <Card
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        minW={"500px"}
        px={"20px"}
        py={"20px"}
        {...rest}
      >
        <Flex w="100%" h={"100%"} flexDir="column" fontSize={"16px"} >
          {variant === 'dealOverview' && <>
            <Flex w={"100%"} mb={"10px"}>
              <Stack
                h="100%"
                w="100%"
                alignItems={"start"}
                direction="row"
                spacing={2}
              >
                <Flex flexDir={"column"} w={lastMeetingId ? "120%" : "100%"}>
                  <Text fontWeight={"bold"} color={textColor}>
                    {variant === 'dealOverview' && 'Meeting Date and Time'}
                  </Text>
                  <Text fontWeight={"normal"}>{getFormattedDateTime(meetingData) || "No future meetings scheduled"}</Text>
                </Flex>
                {meetingLink &&
                  <Flex flexDir={"column"} w={"100%"}>
                    <Box>
                      <Text fontWeight={"bold"} color={textColor}>
                        Meeting link
                      </Text>
                      <Link href={meetingData.meetingLink} isExternal color={linkColor} >
                        {meetingLink.hostname}
                        <ExternalLinkIcon mx="4px" boxSize={"12px"} />
                      </Link>
                    </Box>
                  </Flex>
                }
                {meetingData &&
                  <Flex flexDir={"column"} w={"100%"} display={'none'}>
                    <Box>
                      <Text fontWeight={"bold"} color={textColor}>
                        One Click Call
                      </Text>
                      <Flex alignItems="start">
                        <Text as="span" fontSize={"md"}>
                          202-432-2990
                        </Text>
                        <Box as="button" onClick={onCopy}>
                          <Image src={CopyIcon} alt="Copy Icon" color={linkColor} />
                        </Box>
                      </Flex>
                    </Box>
                  </Flex>
                }
                {lastMeetingId &&
                  <Flex flexDir={"column"} w={"100%"}>
                    <Box>
                      <Text fontWeight={"bold"} color={textColor}>
                        Last Call Summary
                      </Text>
                      <MeetingOverviewLink meetingId={lastMeetingId} />
                    </Box>
                  </Flex>
                }
              </Stack>
            </Flex>
            <Divider mb={"10px"} borderColor={"blackAlpha.400"} />
          </>}
          {variant === "dealOverview" && meetingData && <Flex w={"100%"} h={"100%"} >
            <Stack
              h="100%"
              w="100%"
              alignItems={"start"}
              direction="row"
              spacing={2}
            >
              <Flex flexDir={"column"} w={"100%"}>
                <Flex w={"100%"} mb={'20px'}>
                  <Box mb="15px" h={'100%'} mr={'20px'}>
                    <Text fontWeight={"bold"} color={textColor} mb="10px">
                      Attendees
                    </Text>
                    <List spacing={3} mr={"14px"} minW={'150px'} h={'100%'}>
                      {meetingData.attendees?.map(
                        (attendee: any, index: number) => (
                          <LinkBox as='article' w={'100%'} key={index}>
                            <ListItem>
                              {attendee._id && <LinkOverlay as={ReactLink} to={{
                                pathname: `/prospects/overview/${attendee._id}`,
                                state: {
                                  routeName: `${attendee.name}`
                                }
                              }} >
                              </LinkOverlay>}
                              <Flex alignItems='center'>
                                {attendee._id && <Avatar w="20px" h="20px" mr="14px" src={attendee.pictureLink} />}
                                <Box>
                                  <Text color={nameColor} fontSize='md' fontWeight='700'>
                                    {attendee.name || attendee}
                                  </Text>
                                </Box>
                              </Flex>
                            </ListItem>
                          </LinkBox>
                        )
                      )}</List>
                    <List spacing={3} mr={"14px"} minW={'150px'} h={'100%'}>
                      {(!meetingData.attendees || meetingData.attendees?.length < 1) && meetingData.attendeesEmails?.map(
                        (email: any, index: number) => (
                          <ListItem key={index}>
                            <Flex alignItems='center'>
                              <Box>
                                <Text color={nameColor} fontSize='md' fontWeight='700'>
                                  {email}
                                </Text>
                              </Box>
                            </Flex>
                          </ListItem>
                        )
                      )}</List>
                  </Box>
                  <Flex>
                    <Box>
                      {meetingData.agenda ?
                        <InsightSectionWithFeedback insights={meetingData.agenda} sectionHeader={agendaText} />
                        :
                        <Text>(No agenda)</Text>
                      }
                    </Box>
                  </Flex>
                </Flex>
                {meetingData && meetingData.generatedSummary && <Flex>
                  <Box>
                    <MeetingSummary meetingData={meetingData} variant={"withoutTitle"} />
                  </Box>
                </Flex>}
              </Flex>
            </Stack>
          </Flex>}
          {variant === "prospectOverview" && prospect && <SuggestedTacticsSection prospect={prospect} />}
          {variant === "prospectOverview" && prospect && <TalkingPointsSection prospect={prospect} />}
        </Flex>
      </Card>
    </LinkBox >
  );
}
