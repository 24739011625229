import { Link as RouterLink } from "react-router-dom";
import { Flex, Text, useColorModeValue, Divider, useToken, Box, LinkBox, LinkOverlay } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { DecisionIcon } from 'components/icons/Icons';
import { DealProgressItem, Meeting } from 'views/deals/Deal'
import { getFormattedDateTime } from './MeetingDetailsCard';

function OwnerDeadlineSection(props: {
  owner: string,
  deadline: string
  variant: 'left' | 'right'
}) {
  const { owner, deadline, variant } = props;
  const textColorSecondary = useColorModeValue("#44546F", "white");
  const alignRule = variant == 'left' ? 'start' : 'end';
  const margin = variant == 'left' ? '0px 0px 0px 6px' : '0px 6px 0px 0px';
  return (
    <Flex justifyContent={'end'} alignItems={'center'}>
      {owner &&
        <Text color={textColorSecondary} fontSize='xs' m={margin} align={alignRule}>
          {owner}
        </Text>}
      {deadline &&
        <Text color={textColorSecondary} fontSize='xs' m={margin} align={alignRule}>
          by {deadline}
        </Text>}
    </Flex>
  )
}

function ProgressActionItem(props: {
  icon: any
  progressContent: any
  variant: 'left' | 'right'
  [x: string]: any
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { icon, progressContent, variant, ...rest } = props;
  const alignRule = variant == 'left' ? 'start' : 'end';
  const cardShadowColor = useToken('colors', 'brand.300');

  if (!progressContent) return null;

  let deadline = '';
  try {
    const date = new Date(progressContent.deadline);
    deadline = date.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
  } catch (error) {
    deadline = progressContent.deadline;
  }

  return (
    <Flex {...rest} justifyContent={alignRule} w={'100%'} >
      {variant == 'right' && <OwnerDeadlineSection owner={progressContent.owner} deadline={deadline} variant={variant} />}
      <Flex w={'calc(50% + 1px)'} alignSelf={alignRule} justifyContent={'space-between'}>
        {variant == 'right' &&
          <>
            <Divider orientation='vertical' borderWidth={'1px'} borderColor={'brand.300'} height={'auto'} />
            <Divider orientation='horizontal' borderWidth={'1px'} borderColor={'brand.300'} width={'10%'} alignSelf={'center'} />
          </>}
        <Card w={'90%'} p={'5px 15px'} my='10px' borderRadius={'8px'} boxShadow={`0px 2px 4px 0px ${cardShadowColor}`} >
          <Flex direction={'column'}>
            <Text color={textColor} mb={'0px'} align={alignRule} fontSize={'sm'}>
              {progressContent.item}
            </Text>
          </Flex>
        </Card>
        {variant == 'left' &&
          <>
            <Divider orientation='horizontal' borderWidth={'1px'} borderColor={'brand.300'} width={'10%'} alignSelf={'center'} />
            <Divider orientation='vertical' borderWidth={'1px'} borderColor={'brand.300'} height={'auto'} />
          </>}
      </Flex>
      {variant == 'left' && <OwnerDeadlineSection owner={progressContent.owner} deadline={deadline} variant={variant} />}
    </Flex >
  );
}


function DealProgressSection(props: {
  item: any
}) {
  const { item } = props
  const textColor = useColorModeValue('navy.700', 'white');
  const boxShadowColor = useToken('colors', 'brand.600');
  if (!item) return null;
  const meeting = item.meeting as Meeting;
  if (!meeting) return null;
  const actionItems = meeting?.actionItems;

  // Sort actionItems by deadline where the closest deadline is last
  const sortedActionItems = actionItems?.sort((a, b) => {
    const dateA = new Date(a.content?.deadline);
    const dateB = new Date(b.content?.deadline);
    return dateB.getTime() - dateA.getTime();
  });


  const userName = localStorage.getItem("loggedUserName");
  // meeting attendees from users company, fallback to logged in user
  let providers = [] as string[];
  providers = meeting?.attendeesByCompany?.provider?.length > 0 ? meeting.attendeesByCompany?.provider : [userName];

  return (
    <>
      <Box px={'15px'}>
        {sortedActionItems && sortedActionItems.map((item, index) => (
          <Flex key={item.insightId} flexDir={'column'}>
            <>
              <ProgressActionItem
                icon={DecisionIcon}
                progressContent={item.content}
                variant={providers.includes(item.content?.owner) ? 'right' : 'left'}
              />
            </>
          </Flex>
        ))}
        {(!sortedActionItems || sortedActionItems.length === 0) &&
          <Flex justifyContent={'center'} w={'100%'} height={'15px'}>
            <Flex justifyContent={'right'} w={'100%'} >
              <Flex w={'calc(50% + 1px)'} justifyContent={'space-between'}>
                <Divider orientation='vertical' borderWidth={'1px'} borderColor={'brand.300'} height={'auto'} />
              </Flex>
            </Flex >
          </Flex>
        }

        <LinkBox as="article" w="100%" h={'100%'}>
          <LinkOverlay
            as={RouterLink}
            to={{
              pathname: `/meetings/overview/${meeting._id}`,
            }}
          />
          <Card w={'60%'} p={'5px 15px'} my='0px' borderRadius={'8px'} boxShadow={`0px 2px 6px 0px ${boxShadowColor}`} justifySelf={'center'}>
            <Flex flexDir='column' p={'10px 20px'} textAlign={"center"} justifySelf={'center'}>
              <Text color={textColor} fontWeight={'500'}>{item.content.name}</Text>
              <Text color={textColor} me='6px' fontSize={'sm'}>
                {item.content.oneLineSummary}
              </Text>
              <Text color={textColor} me='6px' fontSize={'sm'}>
                {getFormattedDateTime(meeting)}
              </Text>
            </Flex>
          </Card>
        </LinkBox>
      </Box>
    </>
  );
}


export function DealProgressContainer(props: {
  dealProgressItems: DealProgressItem[],
  meetings: Meeting[]
}) {
  const { dealProgressItems, meetings } = props

  // an array of objects with dealProgressItems where item.meeting is the meeting with the corresponding id
  const sortedItems = dealProgressItems.map(item => {
    const meeting = meetings.find(meeting => meeting._id === item.content.meetingId);
    return { ...item, meeting }
  });
  // sort the array by meeting.date and meeting.startTime where the most recent meeting is first
  sortedItems.sort((a, b) => {
    if (a.meeting && b.meeting) {
      return new Date(b.meeting.date + 'T' + b.meeting.startTime).getTime() - new Date(a.meeting.date + 'T' + a.meeting.startTime).getTime();
    } else {
      return 0;
    }
  });

  // a dealProgressSection for each meeting
  // cute scroll bar that I really outta move to a global style
  return (
    <Flex w={'100%'} flexDir={'column'}
      pb={'15px'}
      maxH={'550px'}
      flexGrow={1}
      overflow={'hidden'}
      overflowY="scroll"
      css={{
        '&::-webkit-scrollbar': { width: '8px', },
        '&::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.)', borderRadius: '4px', },
        '&:hover::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.1)', },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0)', borderRadius: '4px', },
        '&:hover::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0.3)', },
      }}
    >
      {sortedItems.map((item, index) => (
        <DealProgressSection key={index} item={item} />
      ))}
    </Flex>
  );
}
