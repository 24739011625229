import { Flex, Text, LinkBox, LinkOverlay, Stack, Button, useColorModeValue, Icon, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HSeparator } from 'components/separator/Separator';
import Card from 'components/card/Card';
import { DealPhase, Deal, } from 'views/deals/Deal';
import { DealStageItem } from 'views/deals/overview/components/DealStageCard';
import { useState, useEffect } from 'react';
import { EditIcon } from 'components/icons/Icons';
import DealCardEdit from './DealCardEdit';
import { timestampToDate, dateToYYYYMMDD } from '../Common';
import { getFormattedDateTime } from 'views/deals/overview/components/MeetingDetailsCard';

const subcribeDealUrl = "https://inwise-node-functions2.azurewebsites.net/api/deal-subscribe-user?code=r7yvrNvhX4Xl5UOR2Pja-FvQRZHTRSLXtV0UCOrlKofrAzFuk9L0Hg%3D%3D";

export default function DealCard(props: {
  deal: Deal,
  variant: 'dashboard' | 'overview',
  dealsSubscribed?: string[],
  [x: string]: any,
}) {
  const { deal, variant, dealsSubscribed, ...rest } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  if (!deal) return null;
  const info = deal.dealCardInfo;
  const isClosed = info?.stage?.content == DealPhase.Closed;
  const timeToCloseLabel = isClosed ? 'Time to Close' : 'Est. time to close';
  const direction = 'row'//variant === 'overview' ? 'column' : 'row';
  const date = info?.daysSinceLastInteraction?.content ? timestampToDate(info?.daysSinceLastInteraction?.content) : null;
  const lastInteractionDate = (date && !isNaN(date.valueOf())) ? date : null;
  const lastInteractionString = lastInteractionDate ? dateToYYYYMMDD(lastInteractionDate) : 'Not analyzed yet';

  const [subscribeTooltipText, setSubscribeTooltipText] = useState(dealsSubscribed?.includes(deal._id) ? "Remove from My Deals" : "Add to My Deals");
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({
    stage: info?.stage?.content ?? 'Not analyzed yet',
    likelihoodToClose: info?.likelihoodToClose?.content ?? 'Not calculated yet',
    timeToClose: (info?.estimatedClose?.content) ? ` ${info?.estimatedClose?.content}` : 'Not calculated yet',
    sentiment: info?.sentiment?.content ?? 'Not calculated yet',
    size: info?.size?.content ?? 'Not calculated yet',
    daysSinceLastInteraction: lastInteractionString
  });

  const [isHovered, setIsHovered] = useState(false);
  const iconColorLight = useColorModeValue("#0F738CBF", "white");
  const iconColorDark = useColorModeValue("#0F738C", "white");

  useEffect(() => {
    setSubscribeTooltipText(dealsSubscribed?.includes(deal._id) ? "Remove from My Deals" : "Add to My Deals");
  }, [dealsSubscribed, deal._id]);

  function handleSubscribe(dealId: string) {
    const subscribe = !dealsSubscribed.includes(deal._id);
    fetch(subcribeDealUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken: localStorage.getItem("accessToken"), dealId, subscribe }),
    })
      .then(() => {
        setSubscribeTooltipText(subscribe ? "Remove from My Deals" : "Add to My Deals");
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }

  const handleEditCancelled = () => {
    setEditMode(false);
    setIsHovered(false);
  }

  const handleEditSaved = (updatedValues: any) => {
    setEditedValues({
      stage: updatedValues.stage ?? 'Not analyzed yet',
      likelihoodToClose: updatedValues.likelihoodToClose ?? 'Not calculated yet',
      timeToClose: updatedValues.timeToClose ? ` ${updatedValues.timeToClose} days` : 'Not calculated yet',
      sentiment: updatedValues.sentiment ?? 'Not calculated yet',
      size: updatedValues.size ?? 'Not calculated yet',
      daysSinceLastInteraction: updatedValues.daysSinceLastInteraction,
    });
    setEditMode(false);
    setIsHovered(false);
  }
  console.log('Deal:', deal);
  return (
    <LinkBox as='article'>
      <Card
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        height={'100%'}
        minW={variant == 'dashboard' ? '450px' : '100%'}
        p={'20px'}
        mb={variant == 'dashboard' ? '0px' : '20px'}
        maxW={'550px'}
        {...rest}>
        <Flex display={'flex'} flexDirection={'column'} width={'100%'}>
          {variant == 'dashboard' && <>
            <LinkOverlay as={Link} to={{
              pathname: `/deals/overview/${deal._id}`,
              state: {
                dealSummary: { deal },
                routeName: `Deal with ${deal.name}`
              }
            }}>
            </LinkOverlay>
            <Flex display={'flex'} justifyContent={'space-between'} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} zIndex={2}>
              <Text fontSize={'2xl'} fontWeight={'extrabold'} justifyContent='flex-start' textTransform={'capitalize'} color={'textColor'}>{deal.name}</Text>
              <Tooltip label={subscribeTooltipText}>
                <Button fontSize='xl' bg={'transparent'} color={isHovered ? 'brand.500' : 'blackAlpha.400'} fontWeight='700' size={'sm'} onClick={() => handleSubscribe(deal._id)}>{subscribeTooltipText === 'Add to My Deals' ? '+' : '-'}</Button>
              </Tooltip>
            </Flex>
            <HSeparator mb='16px' />
          </>}
        </Flex>
        <Flex w='100%' h={'100%'} alignItems={'flex-start'} flexDir={direction}>
          {deal.dealCardInfo?.summary?.content?.length > 30 ?
            (<Flex>
              <Flex h={'100%'} w={variant == 'dashboard' ? '25%' : '100%'} minW='150px' maxW='400px' mb={variant == 'dashboard' ? '0px' : '30px'}>
                <Stack h='100%' w='100%' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                  {editMode ? (
                    <DealCardEdit deal={deal} onEditCancelled={handleEditCancelled} onEditSaved={handleEditSaved} />
                  ) : (
                    <>
                      <DealStageItem name={'Deal stage'} value={editedValues.stage} />
                      {!isClosed && <DealStageItem name={'Likelihood to close'} value={editedValues.likelihoodToClose} />}
                      <DealStageItem name={timeToCloseLabel} value={editedValues.timeToClose} />
                      <DealStageItem name={'Sentiment'} value={editedValues.sentiment} />
                      <DealStageItem name={'Size'} value={editedValues.size} />
                      <DealStageItem name={'Last interaction'} value={editedValues.daysSinceLastInteraction} />
                    </>
                  )}
                </Stack>
              </Flex>
              {variant == 'overview' && !editMode &&
                <Flex position="absolute" justifyContent="flex-end" w={'90%'}>
                  {isHovered &&
                    <>
                      <Flex
                        as="button"
                        onClick={() => setEditMode(true)}
                        color={iconColorLight}
                        ml='4px'
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)} p={'5px'}
                      >
                        <Icon as={EditIcon} w="40px" h="40px" bg="#ffffffBF" borderRadius={'50%'} p={'10px'} boxShadow={'0 4px 8px 0 rgba(0,0,0,0.1)'} _hover={{ background: "#ffffff", color: iconColorDark }} />
                      </Flex>
                    </>
                  }
                </Flex>
              }
            </Flex>) : (
              <Flex h={'100%'} w={'100%'} mb={variant == 'dashboard' ? '0px' : '30px'} direction='column'>

                {(deal.prospectSumm || (deal.stakeholdersList?.length > 0 && deal.stakeholdersList[0]?.profSummary)) &&
                  <>
                    {(deal.prospectName || deal.stakeholdersList[0]?.name) && <Text fontWeight={'bold'} fontSize={'lg'} mb={'10px'} color={textColor}>
                      {deal.prospectName || deal.stakeholdersList[0]?.name}
                    </Text>}
                    < Text fontWeight={'normal'} mb={'20px'}>
                      {deal.prospectSumm || deal.stakeholdersList[0]?.profSummary}
                    </Text>
                  </>
                }
                {(deal.companySumm || deal.account?.companySumm) &&
                  <>
                    <Text fontWeight={'bold'} fontSize={'lg'} mb={'10px'} color={textColor}>
                      Company Overview
                    </Text>
                    < Text fontWeight={'normal'} mb={'20px'}>
                      {deal.companySumm || deal.account?.companySumm}
                    </Text>
                  </>
                }
                {deal.futureMeetings?.length > 0 &&
                  <>
                    <Text fontWeight={'bold'} fontSize={'lg'} mb={'10px'} color={textColor}>
                      Upcoming Meetings
                    </Text>
                    {deal.futureMeetings.map((meeting, index) => {
                      return (
                        <Flex key={index} direction={'column'} mb={'10px'} ml={'10px'}>
                          <Text fontWeight={'semibold'} fontSize={'sm'} mb={'5px'}>
                            {meeting.name}
                          </Text>
                          <Text fontWeight={'normal'} fontSize={'sm'} color={textColorTertiary}>
                            {getFormattedDateTime(meeting)}
                          </Text>
                        </Flex>
                      )
                    }
                    )}
                  </>
                }
              </Flex>

            )}
          {deal.dealCardInfo?.summary?.content && !editMode && <Flex
            pl={variant == 'dashboard' ? '10px' : '0px'}
            flexGrow={1}
            overflow={'hidden'}
            overflowY={"scroll"}
            maxHeight={'300px'}
            css={{
              '&::-webkit-scrollbar': { width: '8px', },
              '&::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.)', borderRadius: '4px', },
              '&:hover::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.1)', },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0)', borderRadius: '4px', },
              '&:hover::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0.3)', },
            }}
          >
            <Text fontWeight={'normal'}>
              {deal.dealCardInfo.summary.content}
            </Text>
          </Flex>}
        </Flex>
      </Card>
    </LinkBox >
  );
}
