import { useState, useEffect } from 'react';
import { Flex, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import { Meeting, Deal } from 'views/deals/Deal';
import MeetingCard from './MeetingCard';
import SelectDealModal from './SelectDealModal';
import SelectAttendeeModal from './SelectAttendeeModal';
import Card from 'components/card/Card';
import SearchAttendeeByEmailModal from './SearchAttendeeByEmailModal';
import MeetingOverviewModal from './MeetingOverviewModal';

const getMeetingsUrl = "https://appwise-functions.azurewebsites.net/api/get-meeting-list?code=nFlPgiCIapWXCjE5pmL9VLYfHDSsnQD0pMuRGmzwz8aRAzFuw_k0Wg==";
const getDealsUrl = "https://appwise-functions.azurewebsites.net/api/get-deal-list?code=Uzdq07FN-GAKeU0ohoT5_KO1piFGSamE2ulW5Upf991rAzFuSMqWXw%3D%3D";
const assignUrl = "https://inwise-node-functions2.azurewebsites.net/api/assignMeetingToDeal?code=U9d9CPA-iDV2w-LN8e4VqUwBhhdzkoOdRHLsTxRf7C0CAzFu5-ufbg==";

export default function PastMeetings(props: { onDealSummariesUpdated: Function, onDashboardRefresh: Function }) {
  const { onDealSummariesUpdated, onDashboardRefresh } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [pastMeetings, setPastMeetings] = useState<Meeting[]>();
  const [deals, setDeals] = useState<Deal[]>([]);
  const { isOpen: isMeetingOverviewModalOpen, onOpen: onMeetingOverviewModalOpen, onClose: onMeetingOverviewModalClose } = useDisclosure();
  const { isOpen: isDealModalOpen, onOpen: onDealModalOpen, onClose: onDealModalClose } = useDisclosure();
  const { isOpen: isAttendeeModalOpen, onOpen: onAttendeeModalOpen, onClose: onAttendeeModalClose } = useDisclosure();
  const { isOpen: isEmailResultsModalOpen, onOpen: onEmailResultsModalOpen, onClose: onEmailResultsModalClose } = useDisclosure();
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);
  const [selectedAttendeeEmail, setSelectedAttendeeEmail] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();

  useEffect(() => {
    fetch(getMeetingsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId"),
        accessToken: localStorage.getItem("accessToken")
      }),
    })
      .then(res => res.json())
      .then(data => {
        let meetings = data as Meeting[];
        meetings = meetings.filter(meeting => {
          const meetingDateTime = new Date(`${meeting.date}T${meeting.startTime}`);
          return (meetingDateTime < new Date() && meeting.generatedSummary);
        });
        console.log(meetings);
        setPastMeetings(meetings);
      })
      .catch((error: Error) => {
        console.log(error);
      });
    fetchDeals();

  }, [refreshKey]);

  const fetchDeals = async () => {
    fetch(getDealsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId") }),
    })
      .then(res => res.json())
      .then(data => {
        setDeals(data);
        onDealSummariesUpdated?.(data)
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }

  const handleViewMeetingClick = (meeting: Meeting) => {
    setSelectedMeeting(meeting);
    onMeetingOverviewModalOpen();
  }

  const handleAddToDealClick = (meeting: Meeting) => {
    setSelectedMeeting(meeting);
    onMeetingOverviewModalClose();
    onDealModalOpen();
  };

  const handleDealSelected = (deal: Deal | 'create') => {
    if (deal === 'create') {
      onDealModalClose();
      onAttendeeModalOpen();
    } else {
      setSelectedDeal(deal);
      setIsLoading(true);
      fetch(assignUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          meetingId: selectedMeeting?._id,
          dealId: deal._id
        }),
      })
        .then(res => res.json())
        .then(data => {
          setIsLoading(false);
          onDealModalClose();
          if (data.success) {
            toast({
              title: "Success",
              description: "Meeting assigned to deal successfully.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            setRefreshKey(oldKey => oldKey + 1);
          } else {
            toast({
              title: "Error",
              description: "Failed to assign meeting to deal.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        })
        .catch((error: Error) => {
          setIsLoading(false);
          console.log(error);
          toast({
            title: "Error",
            description: "An error occurred while assigning meeting to deal.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  };

  const handleAttendeeEmailSelected = (attendeeEmail: string) => {
    setSelectedAttendeeEmail(attendeeEmail)
    onAttendeeModalClose();
    onEmailResultsModalOpen()
  };
  //prospect/deal/whatever
  const handleProspectCreated = () => {
    //create deal;
    onEmailResultsModalClose();
    //this may or may not refresh the cards idk.
    fetchDeals();
    // other stuff
    toast({
      title: "Success",
      description: "Prospect created and deal assigned successfully.",
      status: "success",
      duration: 5000,
      isClosable: true,
      position: "bottom",
    });
    setRefreshKey(oldKey => oldKey + 1);
    onDashboardRefresh();
  };

  const onBackToAttendeeList = () => {
    onEmailResultsModalClose();
    onAttendeeModalOpen()
  }

  if (!pastMeetings || pastMeetings.length < 1) return null;
  pastMeetings.sort((a, b) => {
    return new Date(b.date + 'T' + b.startTime).getTime() - new Date(a.date + 'T' + a.startTime).getTime();
  });

  return (
    <Card p={4} w='100%' bg='white' mb={'20px'}>
      <Text color={textColor} fontSize='xl' fontWeight='bold' mb='10px' ml='15px'>
        Past Meetings
      </Text>
      <Flex
        pr={'10px'}
        direction='column'
        maxHeight='250px'
        overflowY="scroll"
        css={{
          '&::-webkit-scrollbar': { width: '8px' },
          '&::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: '4px' },
          '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0.3)', borderRadius: '4px' },
        }}>
        {pastMeetings.map((meeting, index) => (
          <MeetingCard key={index} meeting={meeting} variant='pastMeetings' mb={'10px'} onAddToDealClick={() => {
            if ((!meeting.attendees && !meeting.attendeesEmails) || (meeting.attendees?.length === 0 && meeting.attendeesEmails?.length === 0)) {
              window.location.href = '/deals/create';
            } else {
              handleAddToDealClick(meeting);
            }
          }}
            onViewMeetingClick={handleViewMeetingClick}
          />
        ))}
      </Flex>
      {selectedMeeting && (
        <MeetingOverviewModal
          meeting={selectedMeeting}
          isOpen={isMeetingOverviewModalOpen}
          onClose={onMeetingOverviewModalClose}
          onAddToDealClicked={handleAddToDealClick}
        />
      )}
      {selectedMeeting && (
        <SelectDealModal
          isOpen={isDealModalOpen}
          onClose={onDealModalClose}
          deals={deals}
          onDealSelected={handleDealSelected}
          isLoading={isLoading}
        />
      )}
      {selectedMeeting && (
        <SelectAttendeeModal
          isOpen={isAttendeeModalOpen}
          onClose={onAttendeeModalClose}
          meeting={selectedMeeting}
          onAttendeeSelected={handleAttendeeEmailSelected}
        />
      )}
      {selectedMeeting && selectedAttendeeEmail && (
        <SearchAttendeeByEmailModal
          isOpen={isEmailResultsModalOpen}
          onClose={onBackToAttendeeList}
          attendeeEmail={selectedAttendeeEmail}
          onCreated={handleProspectCreated}
          meetingId={selectedMeeting._id}
        />
      )}
    </Card>
  );
}
