import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, } from '@chakra-ui/react';
import { Meeting } from 'views/deals/Deal';
import { useState } from 'react';
import { BackButton, NextButton } from 'views/common/common';

export default function SelectAttendeeModal(props: { isOpen: boolean, onClose: () => void, meeting: Meeting, onAttendeeSelected: (attendeeEmail: string, meetingId: string) => void }) {
  const { isOpen, onClose, meeting, onAttendeeSelected } = props;
  const [selectedAttendee, setSelectedAttendee] = useState<string>('');
  const handleAttendeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAttendee(event.target.value);
  };
  const handleAttendeeSelected = async () => {
    if (!selectedAttendee) { return; }
    onAttendeeSelected(selectedAttendee, meeting._id);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select primary prospect</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {meeting.attendees?.length ? (
            meeting.attendees.map((attendee, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={`attendee-${index}`}
                  name="attendee"
                  value={attendee}
                  checked={selectedAttendee === attendee}
                  onChange={handleAttendeeChange}
                />
                <label htmlFor={`attendee-${index}`}> {attendee}</label>
              </div>
            ))
          ) : (
            meeting.attendeesEmails.map((email, index) => (
              <div key={index}>
                <input
                  type="radio"
                  id={`email-${index}`}
                  name="attendee"
                  value={email}
                  checked={selectedAttendee === email}
                  onChange={handleAttendeeChange}
                />
                <label htmlFor={`email-${index}`}> {email}</label>
              </div>
            ))
          )}
        </ModalBody>
        <ModalFooter>
          <BackButton mr={2} onClick={onClose} buttonText={'Cancel'} w={'100px'} />
          <NextButton
            isDisabled={!selectedAttendee}
            onClick={handleAttendeeSelected}
            buttonText={'Next'} ml='10px' w={'100px'} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
