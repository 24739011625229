import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Select, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import { Deal, Meeting } from 'views/deals/Deal';
import MeetingCard from 'views/deals/overview/components/MeetingCard';
import { getFormattedDateTime } from 'views/deals/overview/components/MeetingDetailsCard';
import Card from 'components/card/Card';

function AssignMeetingsTab(props: {
  [x: string]: any;
}) {
  const { ...rest } = props;
  const getUnassignedMeetingsUrl = "https://appwise-functions.azurewebsites.net/api/get-meeting-not-assigned?code=q7P7hSo1aUHJQDUJFF3lmHy6HpfqzyTGKFhl1ohB1flnAzFuJzsPJw%3D%3D"
  const getDealsUrl = "https://appwise-functions.azurewebsites.net/api/get-deal-list?code=Uzdq07FN-GAKeU0ohoT5_KO1piFGSamE2ulW5Upf991rAzFuSMqWXw%3D%3D";
  const assignMeetingToDealUrl = "https://inwise-node-functions2.azurewebsites.net/api/orchestrators/assign-meeting-to-deal-orchestration?code=NvO3RzqrIOvIT--PtNm42g6gezEwgpLeRVTivmbSmi0dAzFuanX63g%3D%3D"

  const [meetings, setMeetings] = useState<Meeting[]>()
  const [deals, setDeals] = useState<Deal[]>()
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();
  const [selectedDeal, setSelectedDeal] = useState<Deal>();
  const toast = useToast();

  useEffect(() => {
    if (meetings) {
      return;
    }
    fetch(getUnassignedMeetingsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId") })
    })
      .then(res => res.json())
      .then(data => {
        let meetings = data.meetings as Meeting[];
        setMeetings(meetings);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (deals) {
      return;
    }
    fetch(getDealsUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId"),
      }),
    })
      .then(res => res.json())
      .then(data => {
        setDeals(data);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, []);

  const handleDealSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const deal = deals.find(deal => deal._id === event.target.value) || null;
    setSelectedDeal(deal)
  }

  const handleSubmit = async () => {
    try {
      const response = await fetch(assignMeetingToDealUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ meetingId: selectedMeeting._id, dealId: selectedDeal._id }),
      });
      if (!response.ok) {
        console.error('Failed to reassign meeting');
        toast({
          position: "top",
          title: "Failed to assign meeting",
          description: `Failed to assign "${selectedMeeting.name}" to ${selectedDeal.name}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
        return;
      }
      else {
        setMeetings(meetings?.filter(meeting => meeting._id !== selectedMeeting._id));
        toast({
          position: "top",
          title: "Meeting assigned",
          description: `"${selectedMeeting.name}" has been assigned to ${selectedDeal.name}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      }
    }
    catch (error) { console.error('Error during fetch:', error) }
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  if (!meetings) return null;

  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Flex>
        <Text fontSize={'lg'} fontWeight={'700'} color={textColor} >Assign unassigned meetings to an existing deal</Text>
      </Flex>
      <HSeparator mt='10px' />
      {meetings.length > 0 ? <Flex flexDir={'column'} pt='25px' px={'10px'} w={'100%'}>
        <MeetingCard
          meetings={meetings}
          variant='full'
          onSelectionChanged={function (selectedMeeting: Meeting): void { setSelectedMeeting(selectedMeeting) }}
          mb={'30px'} />
        <Text color={textColor} mb={'20px'} >Selected Meeting: </Text>
        <Flex pl={'10px'} mb={'20px'} >
          {selectedMeeting &&
            <Card py={'10px'} borderRadius={'8px'}>
              <Text color={textColor} >{selectedMeeting.name} {getFormattedDateTime(selectedMeeting)}</Text>
            </Card>}
        </Flex>
        <Text color={textColor} mb={'20px'} >Selected Deal: </Text>
        <Flex pl={'10px'} mb={'20px'} >
          <Select onChange={handleDealSelected} borderRadius={'8px'} placeholder='Select deal'>
            {deals?.map((deal) => (<option key={deal._id} value={deal._id}>{deal.name}</option>))}
          </Select>
        </Flex>
        <Flex justifyContent='flex-end' mt='24px'>
          <Button
            variant={'brand'}
            borderWidth={3}
            fontWeight='400'
            w='200px'
            h='50px'
            onClick={handleSubmit}
            isDisabled={!selectedDeal}
          >
            Assign to deal
          </Button>
        </Flex>
      </Flex> :
        <Flex flexDir={'column'} pt='25px' px={'10px'} w={'100%'}>
          <Text color={textColor} mb={'20px'} >There are currently no unassigned meetings. </Text>
        </Flex>
      }
    </Box>
  )
}

export default AssignMeetingsTab;
