import { useState } from 'react';
import { Flex } from '@chakra-ui/react';
import TodoList from './components/TodoList';
import Dashboard from './components/Dashboard';
import { useLocation } from 'react-router-dom';
import { LocationProps, } from 'views/deals/Deal';
import FutureMeetings from './components/FutureMeetings';
import PastMeetings from './components/PastMeetings';

export default function DealsDashboard() {
	const location = useLocation() as LocationProps;
	const dealSummaries = location?.state?.dealSummaries;
	const onDealSummariesUpdated = location?.state?.onDealSummariesUpdated;
	const [dashboardRefreshKey, setDashboardRefreshKey] = useState(0);

	const handleDashboardRefresh = () => {
		// Logic to refresh the Dashboard component
		setDashboardRefreshKey(oldKey => oldKey + 1);
	};

	return (
		<Flex pt={{ base: '130px', md: '80px', xl: '80px' }} h='max-content'>
			<Flex w='90%'>
				<Dashboard
					key={dashboardRefreshKey}
					dealSummaries={dealSummaries}
					onDealSummariesUpdated={onDealSummariesUpdated}
				/>
			</Flex>
			<Flex h='100%' p={'10px'} flexDir='column' minW={'450px'} position={'sticky'} right={0}>
				<FutureMeetings onDealSummariesUpdated={onDealSummariesUpdated} onDashboardRefresh={handleDashboardRefresh} />
				<PastMeetings onDealSummariesUpdated={onDealSummariesUpdated} onDashboardRefresh={handleDashboardRefresh} />
				<TodoList />
			</Flex>
		</Flex>
	);
}
