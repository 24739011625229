import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';

const getCalendarsUrl = 'https://appwise-functions.azurewebsites.net/api/get-calendar-list?code=3nfIuNXN3eYUSWqtnE1st8Yx6qCsAGbkKgcSzJ1PEkykAzFupP34hA%3D%3D';
const updateCalendarPreferencesUrl = "https://appwise-functions.azurewebsites.net/api/edit-calendar-settings?code=u6yQOMJ7kQjcsJipg2xd355Zyzlkp2eQ1-K3plGLzwCCAzFuQmpbiw%3D%3D";

interface Calendar {
  id: string;
  summary: string;
  selected: boolean;
}

const ManageCalendars = () => {
  const [calendars, setCalendars] = useState<Calendar[]>([]);
  const [takeInternals, setTakeInternals] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const toast = useToast();

  useEffect(() => {
    const fetchCalendars = async () => {
      const wiseAccessToken = localStorage.getItem("accessToken");
      const response = await fetch(getCalendarsUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ wiseAccessToken })
      });
      const calendarData = await response.json();
      if (calendarData.error === 'invalid_grant') {
        toast({
          title: "Session expired",
          description: "Please log out and log back in to continue.",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
      setCalendars(calendarData.calendarList.map((cal: any) => ({ id: cal.id, summary: cal.summary, selected: cal.selected })));
      setTakeInternals(calendarData.takeInternals);
    };

    fetchCalendars();
  }, [toast]);

  const handleCheckboxChange = (id: string) => {
    setCalendars(calendars.map(cal => cal.id === id ? { ...cal, selected: !cal.selected } : cal));
    setIsButtonDisabled(false);
  };

  const handleTakeInternalsChange = () => {
    setTakeInternals(!takeInternals);
    setIsButtonDisabled(false);
  };

  const handleSubmit = async () => {
    setIsButtonDisabled(true);
    const wiseAccessToken = localStorage.getItem("accessToken");
    const selectedCalendars = calendars.filter(cal => cal.selected).map(cal => cal.id);
    const response = await fetch(updateCalendarPreferencesUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ wiseAccessToken, selectedCalendars, takeInternals })
    });
    if (response.ok) {
      toast({
        title: "Preferences updated",
        description: "Your calendar preferences have been updated successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description: "There was an error updating your calendar preferences.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setTimeout(() => setIsButtonDisabled(false), 10000);
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Flex>
        <Text fontSize={'lg'} fontWeight={'700'} color={textColor}>Manage Calendar Preferences</Text>
      </Flex>
      <HSeparator mt='10px' />
      <FormControl pt='25px' px={'10px'} w={'100%'}>
        <FormLabel fontSize='md' fontWeight='400' color={textColor} mb='8px'>
          Select the calendars you want to sync:
        </FormLabel>
        {calendars.map(calendar => (
          <Box key={calendar.id} mb='8px'>
            <Checkbox
              isChecked={calendar.selected}
              onChange={() => handleCheckboxChange(calendar.id)}
            >
              {calendar.summary}
            </Checkbox>
          </Box>
        ))}
        <FormLabel fontSize='md' fontWeight='400' color={textColor} mb='8px'>
          Additional settings:
        </FormLabel>
        <Box mb='8px'>
          <Checkbox
            isChecked={takeInternals}
            onChange={handleTakeInternalsChange}
          >
            Schedule bots also for internal meetings
          </Checkbox>
        </Box>
        <Flex justifyContent='flex-end' mt='24px'>
          <Button
            variant={'brand'}
            borderWidth={3}
            fontWeight='400'
            w='200px'
            h='50px'
            onClick={handleSubmit}
            isDisabled={isButtonDisabled}
          >
            Save Preferences
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
};

export default ManageCalendars;
