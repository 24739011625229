import React, { useState } from 'react';
import { Box, Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Text, useColorModeValue } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { HSeparator } from 'components/separator/Separator';

function DeleteAccountTab(props: {
  [x: string]: any;
}) {
  const { ...rest } = props;
  const deleteAccountUrl = "https://inwise-node-functions2.azurewebsites.net/api/user-delete-account?code=Y8SZa8U7ecPQyB6r60jvdJyFvdeS-lAfrrShJj3Z7L7ZAzFun2Ld5g%3D%3D";
  const [email, setEmail] = useState('');

  const isFormValid = () => {
    return email.trim() !== '';
  };

  const handleSubmit = async () => {
    console.log(`Requesting account deletion for ${email}`)
    const accessToken = localStorage.getItem("accessToken");
    fetch(deleteAccountUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken, login: email })
    })
      .then(res => res.json())
      .then(response => {
        console.log(response, response.deleted);
        if (response.deleted) {
          localStorage.clear();
          window.location.replace("/");
        } else {
          if (response.errorMessage) {
            alert(response.errorMessage);
          } else {
            alert('Failure at deleting account');
          }
        }
      });
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Box mb={{ base: '20px', md: '0px' }} minW={'310px'} p={'15px'}>
      <Flex>
        <Text color='red' fontSize={'lg'} fontWeight={'700'} >Delete Account</Text>
      </Flex>
      <HSeparator mt='10px' />
      <Flex pt={'20px'} px={'10px'}>
        <Text fontSize={'md'} fontWeight='400' color={textColor}>Are you sure? This action is permanent and <b>CANNOT</b> be undone.</Text>
      </Flex>
      <FormControl pt='25px' px={'10px'} w={'100%'}>
        <Flex>
          <Flex flexDir={'column'} w={'50%'}>
            <FormLabel display='flex' fontSize='md' fontWeight='400' color={textColor} mb='8px'>
              Enter the email linked to your account to confirm:
            </FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <EmailIcon color='gray.300' />
              </InputLeftElement>
              <Input
                isRequired={true}
                variant='outline'
                fontSize='sm'
                ms={{ base: '0px', md: '0px' }}
                type='email'
                placeholder='example@email.com'
                mb='24px'
                fontWeight='400'
                size='lg'
                onChange={e => setEmail(e.target.value)}
              />
            </InputGroup>
          </Flex>
        </Flex>
        <Flex justifyContent='flex-end' mt='24px'>
          <Button
            variant={'brand'}
            borderWidth={3}
            fontWeight='400'
            w='200px'
            h='50px'
            onClick={handleSubmit}
            isDisabled={!isFormValid()}>
            Delete Account
          </Button>
        </Flex>
      </FormControl>
    </Box>
  )
}

export default DeleteAccountTab;
